import React, { useCallback, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'

import { REQUEST_PASSWORD_RESET } from 'common/User/graphql'

import EmailInput from './EmailInput'
import Password from './Password'
import { PasswordContext, PasswordProvider, message } from './reducer'

/*
 *  Kindof a Pita, but to stay DRY this doesn't use any Global or User Contexts,
 *  so it can be referenced both as a signed in user and anonymously to reset
 *  a password
 */

function ChangePassword({ email = undefined, factors = {}, allowed = {} }) {
  const { targetId, code } = useParams()

  const [request] = useMutation(REQUEST_PASSWORD_RESET)
  const requestReset = useCallback(
    (email, dispatch) => {
      request({
        variables: { email, id: targetId },
        onCompleted({ requestPasswordReset: { success, reason } }) {
          if (success) {
            message(dispatch, 'code', 'submitted')
          } else {
            message(dispatch, 'code', 'reason', reason)
          }
        }
      })
    },
    [request, targetId]
  )

  return (
    <PasswordProvider
      email={email}
      factors={factors}
      userId={targetId}
      requestReset={requestReset}
      allowed={allowed}
      code={code}
    >
      <ChangePasswordInner />
    </PasswordProvider>
  )
}

function ChangePasswordInner() {
  const [{ id, code, factors, allowed }] = useContext(PasswordContext)

  return (
    <div className="mb4">
      {id && factors ? (
        <>
          <label className="f4 b db mb3 heading">Change Password</label>
          {!factors.password && (
            <div className="mb3">
              You are signed in with
              <b>
                {allowed.google
                  ? ' Google Authentication '
                  : ' Federated Authentication '}
              </b>
              which doesn't use a password.
              <div className="mb2" />
              In order to set a password, you need to request a Password Reset
              Code.
            </div>
          )}
          <Password />
        </>
      ) : code.value ? (
        <Password />
      ) : (
        <EmailInput />
      )}
    </div>
  )
}

export default ChangePassword
