import React, { useCallback, useContext, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'

import notifier from 'tools/Notify'
import { Label } from 'tools/Uniform'

export const CHG = {
  OK: 0,
  DIRTY: 1,
  FAILED: 2,
  SAVED: 3,
  SAVING: 4
}

////////////////////////////////////////////////////////////////////////////////
// TEMPLATE for onSave function:
//   props={}           -- list of extra props sent to the Input
//   value=""           -- the save value for this data
//   good/bad(optional) -- callback functions for each condition
//   error(optional)    -- function to set error message
function deferSave(props, value, good, bad, error) {
  good()
}

const INPUT_LINE = 1
const INPUT_NUMERIC = 2
const INPUT_TEXT = 3

export const INPUT_TYPES = {
  INPUT: INPUT_LINE,
  NUMERIC: INPUT_NUMERIC,
  TEXTAREA: INPUT_TEXT
}

const defaultFixed = {
  onKeyDown: undefined,
  className: undefined,
  rows: undefined,
  onChange: () => {},
  style: undefined
}

function useInputs({
  type,
  args: {
    maxLength = 0,
    value,
    onChange: changer,
    selected = undefined,
    validate = undefined,
    onSave = deferSave,
    rows = undefined,
    minRows = 3,
    minmax = defaultMinMax,
    inputClass = undefined,
    autoWidth = 0,
    startDirty = false,
    autoSave = true,
    prefix = undefined,
    numeric = false,
    makeVars = undefined,
    ...args
  }
}) {
  const [, notifyDispatch] = useContext(notifier.Context)
  const [dirty, setDirty] = useState(startDirty ? CHG.DIRTY : CHG.OK)
  const [orig, setOrig] = useState(value)
  const [mesg, setMesg] = useState(<></>)
  const [fixed, setFixedArgs] = useState(defaultFixed)
  const variable = { ...args }

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  const setSaving = useCallback(
    (status) => {
      setDirty(status)
      if (status === CHG.DIRTY) {
        notifier.editing(notifyDispatch)
      } else if (status === CHG.SAVED) {
        notifier.saved(notifyDispatch)
      }
    },
    [setDirty, notifyDispatch]
  )

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  const error = useCallback(
    (errmsg) => setMesg(<div className="pv2 f4 red">{errmsg}</div>),
    [setMesg]
  )

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  const save = useCallback(
    (value) => {
      return new Promise((resolve, reject) => {
        if (numeric) {
          value = parseFloat(value.replace(/[^a-z0-9.]+/g, '') || '0')
        }
        setSaving(CHG.SAVING)
        return onSave(
          { ...args, ...fixed, makeVars, orig, dirty },
          value,
          resolve,
          reject,
          error
        )
      })
        .then((wat = CHG.SAVED) => {
          setMesg(<></>)
          setSaving(wat)
          setOrig(value)
        })
        .catch((errmsg) => {
          error(errmsg)
          setSaving(CHG.FAILED)
        })
    },
    [
      fixed,
      args,
      dirty,
      error,
      setMesg,
      onSave,
      setSaving,
      numeric,
      orig,
      makeVars
    ]
  )

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  const { token } = args
  const onChange = useCallback(
    (ev) => {
      let val = ev.target.value
      if (validate) {
        const result = validate(val)
        if (result.error) {
          error(result.error)
          return
        }
        val = result.value
      } else if (maxLength) {
        if (val.length > maxLength) {
          changer(val.substring(0, maxLength))
          setSaving(CHG.DIRTY)
          error(`You cannot use more than ${maxLength} characters, sorry`)
          return
        }
      }
      changer(val, token)
      setSaving(CHG.DIRTY)
    },
    [validate, error, changer, maxLength, setSaving, token]
  )

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  useEffect(() => {
    let isMounted = true
    if (dirty === CHG.SAVED) {
      setTimeout(() => {
        if (isMounted) {
          setSaving(CHG.OK)
          setMesg(null)
        }
      }, 3000)
    }
    return () => {
      isMounted = false
    }
  }, [dirty, setSaving])

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  useEffect(() => {
    const fixed = {}
    if (type === INPUT_TEXT) {
      fixed.onKeyDown = undefined
      if (rows) {
        fixed.rows = rows
      } else {
        fixed.rows =
          (value || '')
            .split(/\n/)
            .reduce((acc, line) => acc.concat(line.match(/.{1,80}/g)), [])
            .length + 1
        if (fixed.rows > 25) {
          fixed.rows = 25
        }
        if (fixed.rows < minRows) {
          fixed.rows = minRows
        }
      }
    }
    fixed.prefix = prefix
    if (!inputClass) {
      if (prefix) {
        fixed.className = 'pl2 w-100'
      } else {
        fixed.className = 'w-100'
      }
    } else {
      fixed.className = inputClass
    }
    fixed.onChange = onChange
    if (autoWidth) {
      let width = (`${value}`.length + 1) * autoWidth
      if (width < minmax[0]) {
        width = minmax[0]
      } else if (width > minmax[1]) {
        width = minmax[1]
      }
      fixed.style = { width: `${width}rem` }
    }
    setFixedArgs(fixed)
  }, [
    setFixedArgs,
    prefix,
    value,
    onChange,
    autoWidth,
    inputClass,
    minRows,
    minmax,
    rows,
    type
  ])

  variable.onKeyDown = useCallback((ev) => {
    if (ev.key === 'Enter') {
      ev.target.blur()
    }
  }, [])

  variable.onBlur = useCallback((e) => save(value), [value, save])
  if (!autoSave) {
    variable.onBlur = undefined
  }
  variable.onFocus = useCallback(
    (e) => (selected ? e.target.select() : undefined),
    [selected]
  )
  variable.value = value ?? ''

  return { fixed, variable, dirty, mesg, onChange }
}

////////////////////////////////////////////////////////////////////////////////
const defaultMinMax = [3, 15]
export function Input({
  type = INPUT_LINE,
  help = undefined,
  label = undefined,
  labelStyle = '',
  icons = true,
  showmax = false,
  wrapClass = '',
  className = '',
  dirtyText = undefined,
  ...args
}) {
  /* @ts-ignore */
  const { fixed, variable, dirty, mesg } = useInputs({ type, args })

  return (
    <div className={`input-inline ${className ? className : label ? 'mt4' : ''}`}>
      {label ? (
        <div className="mb3">
          <Label className={labelStyle}>
            {label}
            <Showlength
              value={variable.value}
              max={args.maxLength}
              dirty={dirty}
            />
          </Label>
        </div>
      ) : null}
      {help ? <div className="f3 mb3 gray">{help}</div> : null}
      <div className={`relative flex-items ${wrapClass}`}>
        {type === INPUT_NUMERIC ? (
          <NumberFormat {...variable} {...fixed} />
        ) : type === INPUT_TEXT ? (
          <textarea {...variable} {...fixed} />
        ) : (
          <input {...variable} {...fixed} />
        )}{' '}
        {icons ? <DirtyIcon dirty={dirty} dirtyText={dirtyText} /> : null}
        {
          //fixed.prefix ? (
          //<div className="absolute input-inner gray ph1">$</div>
          //) : null
        }
      </div>
      {mesg}
    </div>
  )
}

// BJG: for simplicity I'd prefer we don't use these
//
// export function StatefulInput({ initVal, onSave, ...args }) {
//   const [value, onChange] = useState(initVal)
//   return <Input value={value} onChange={onChange} onSave={onSave} {...args} />
// }
//
// export function StatefulTextarea({ initVal, onSave, ...args }) {
//   const [value, onChange] = useState(initVal)
//   return <Input value={valState} type={INPUT_TEXT} onSave={onSave} {...args} />
// }

function Showlength({ value, max, dirty }) {
  if (!max || !dirty) {
    return null
  }
  return (
    <div className={`f2 normal ml2 i ${value.length === max ? '' : 'gray'}`}>
      ({value.length} of {max})
    </div>
  )
}

export function Textarea(args) {
  return <Input type={INPUT_TEXT} {...args} />
}

export function Numeric(args) {
  return <Input type={INPUT_NUMERIC} {...args} />
}

////////////////////////////////////////////////////////////////////////////////
function DirtyIcon({ dirty, className = '', dirtyText = undefined }) {
  if (dirty === CHG.FAILED) return <i className="fas fa-save red ml2" />
  if (dirty === CHG.SAVED) return <i className="fas fa-check green ml2" />
  if (dirty === CHG.DIRTY)
    return (
      <div className="button nowrap ml2 pa1">
        <i
          className={`fas fa-save ${className ? className : 'f4'} ${
            dirtyText ? 'mr2' : ''
          }`}
        />
        {dirtyText}
      </div>
    )
  return null
}

export function useSave({
  id,
  key,
  mutate,
  result = 'result',
  resultArray = false,
  path = undefined,
  normalize
}) {
  const history = useHistory()
  return useCallback(
    (
      { token = undefined, orig = undefined, meta = {}, dirty = true },
      value,
      good,
      bad
    ) => {
      if (!dirty) {
        good && good(value)
      } else if (orig !== value && dirty) {
        mutate({
          variables: { id, [token]: value, ...meta },
          update(cache, { data }) {
            const res = data[key]
            if (res.success && res[result]) {
              let value = res[result]
              if (resultArray) {
                if (value.length > 0) {
                  value = value[0]
                } else {
                  console.log('Unexpected invalid array value as result')
                  bad && bad('Unexpected error')
                  return
                }
              }
              normalize(value)
              if (path && id !== value.id) {
                history.replace(path(value.id))
              } else {
                good && good(value)
              }
            } else {
              bad && bad(res.reason)
            }
          }
        })
      }
    },
    [id, key, mutate, path, history, normalize, result, resultArray]
  )
}

export function useChanger(dispatch, merge_action) {
  return useCallback(
    (value, key) => dispatch({ type: merge_action, value: { [key]: value } }),
    [dispatch, merge_action]
  )
}

export default Input
