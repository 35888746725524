import config from 'config'

import { intcmp } from 'utils/string'

////////////////////////////////////////////////////////////////////////////////
// TODO: move to settings so we don't have to keep frontend in-sync too
// Don't forget to update backend upload_file_resolver when changes are made

const VIDEO_TYPES = { 'video/mp4': 'Video - MP4', 'video/webm': 'Video - WebM' }
const PDF_TYPES = { 'application/pdf': 'PDF' }
const SHEET_TYPES = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'Microsoft Excel (XLSX)',
  'application/vnd.ms-excel': 'Microsoft Excel (XLS)',
  'application/vnd.oasis.opendocument.spreadsheet-template':
    'Open Document Sheet (ODS)'
}
// const PRESO_TYPES = {
//   "application/vnd.ms-powerpoint": "Microsoft PowerPoint (PPT)",
//   "application/vnd.openxmlformats-officedocument.presentationml.presentation": "Microsoft PowerPoint (PPTX)",
//   "application/vnd.oasis.opendocument.presentation": "Open Document Presentation (ODP)"
// }
const IMG_TYPES = {
  'image/png': 'PNG',
  'image/webp': 'WebP'
}

export const FILE_TYPES = [
  {
    type: 'avatar',
    name: 'Avatar',
    icon: 'fas fa-image',
    mime: IMG_TYPES,
    hide: true
  },
  {
    type: 'lightning',
    name: 'Lightning Pitch',
    icon: 'fas fa-bolt',
    explain:
      'a special 1:1 video where you describe your project in a very focused manner by answering a series of questions. It is formatted with one person looking at the camera, a plain light background with no other items showing, as you answer each question.\n\nAs we gain more information we will be able to provide guidance using data analytics on your idea simply from this video.',
    mime: VIDEO_TYPES
  },
  {
    type: 'pitchPdf',
    name: 'Pitch PDF',
    explain: 'A PDF version of your pitch deck',
    icon: 'fas fa-file-powerpoint',
    mime: PDF_TYPES
  },
  {
    type: 'pitchVid',
    name: 'Pitch Video',
    icon: 'fas fa-video',
    explain: 'A video of you presenting your pitch deck.',
    mime: VIDEO_TYPES
  },
  {
    type: 'execsum',
    name: 'Executive Summary',
    icon: 'fas fa-file-powerpoint',
    mime: PDF_TYPES
  },
  {
    type: 'bizplan',
    name: 'Business Plan',
    icon: 'fas fa-file-powerpoint',
    mime: PDF_TYPES
  },
  {
    type: 'sales',
    name: 'Sales Brochure',
    icon: 'fas fa-file-image',
    mime: PDF_TYPES
  },
  {
    type: 'howto',
    name: 'How-to Video',
    icon: 'fas fa-file-video',
    explain: 'Instructional videos on how to use your tool/service/product.',
    mime: VIDEO_TYPES
  },
  {
    type: 'ipfolio',
    name: 'IP Portfolio',
    icon: 'fas fa-brain',
    mime: { ...PDF_TYPES, ...SHEET_TYPES }
  },
  {
    type: 'projections',
    name: 'Financial Projections',
    icon: 'fas fa-chart-line',
    mime: SHEET_TYPES
  },
  {
    type: 'revmodel',
    name: 'Revenue Model',
    icon: 'fas fa-file-invoice-dollar',
    mime: SHEET_TYPES
  },
  {
    type: 'captable',
    name: 'Cap Table',
    icon: 'fas fa-users',
    mime: SHEET_TYPES
  },
  {
    type: 'proforma',
    name: 'Pro Forma',
    icon: 'fas fa-file-invoice-dollar',
    mime: SHEET_TYPES
  },
  {
    type: 'applyVid',
    name: 'Application Video',
    icon: 'fas fa-file-video',
    hide: true,
    mime: VIDEO_TYPES
  }
].reduce((a, r) => {
  a[r.type] = r
  return a
}, {})

// static redundant list from above, so we can choose the order
export const FILE_TYPE_LIST = [
  'lightning',
  'pitchPdf',
  'pitchVid',
  'execsum',
  'bizplan',
  'sales',
  'howto',
  'ipfolio',
  'projections',
  'revmodel',
  'captable',
  'proforma'
]

export const MAX_FILE_SIZE = 5 * 1024 * 1024

////////////////////////////////////////////////////////////////////////////////
export function normalizeFile(file) {
  file = { ...file }
  file._updated = new Date(file.updatedAt).getTime()
  file._updated_t = file._updated.unix()
  if (file.path) {
    file.url = config.pub + file.path
  } else {
    file.url = null
  }
  return file
}

export function normalizeFiles(value) {
  const files = (value || [])
    .map(normalizeFile)
    .sort((a, b) => intcmp(b._updated_t, a._updated_t))

  const fileTypes = files.reduce((a, f) => {
    a[f.type] = (a[f.type] || []).concat(f)
    return a
  }, {})

  return { files, fileTypes }
}

export function normalizeParentFiles(parent, value) {
  const { files, fileTypes } = normalizeFiles(value)

  return { ...parent, files, fileTypes }
}
