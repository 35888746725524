import React, { useContext, useEffect } from 'react'

import { Link } from 'tools/Link'
import { MaxViewPage } from 'tools/Uniform'

import paths from 'common/paths'
import { orgPath, profilePath } from 'hub/paths'
import GlobalContext, { R_SIGNON } from 'reducer/global'
import { usePage } from 'reducer/global/hooks'

export function ShowNotices() {
  const [{ user }, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    // A little heavy handed but currently this is the only way to make sure the global org object is up-to-date
    dispatch({ type: R_SIGNON.RELOAD_USER })
  }, [dispatch])

  const org = user.activeOrg

  if (!org) return null

  const notices = org.notices

  return notices.length ? (
    <ol className="f4 pa3 flex flex-column gv2">
      {notices.map((notice, i) => (
        <li key={i}>
          <div className={notices.type}>
            {notice.reason} <HandleLink orgId={org.shortId} type={notice.type} />
          </div>
        </li>
      ))}
    </ol>
  ) : (
    <i>No notifications</i>
  )
}

function HandleLink({ orgId, type }) {
  switch (type) {
    case 'payment':
      return (
        <Link
          to={orgPath(orgId, 'billing')}
          className="ml1 fas fa-arrow-up-right-from-square"
        />
      )
    case 'address':
      return (
        <Link
          to={orgPath(orgId, 'settings')}
          className="ml1 fas fa-arrow-up-right-from-square"
        />
      )
    case 'verified':
      return (
        <Link
          to={profilePath}
          className="ml1 fas fa-arrow-up-right-from-square"
        />
      )
    default:
      return null
  }
}

export function NoticeBell() {
  const [{ user }] = useContext(GlobalContext)

  const showBell = user?.activeOrg?.notices?.length > 0

  return (
    showBell && (
      <Link to={paths.public.messages}>
        <i className="fa fa-bell" style={{ color: 'var(--warn)' }} />
      </Link>
    )
  )
}

function Notices() {
  usePage({ section: 'Messages', title: 'Messages' }, [])

  return (
    <MaxViewPage>
      <ShowNotices />
    </MaxViewPage>
  )
}

export default Notices
