import { merge } from 'lodash'

import { freeze } from 'utils/dom'
import { intcmp } from 'utils/string'
import { enrichPosixTime } from 'utils/time'
import { normalizeUserParam } from 'utils/user'

import { REQUIREMENTS } from 'common/Signon/password'

import tabs from './tabs'

// TODO: reconcile with utils/user; probably strike utils/user
const defaultPassForm = {
  passMsg: REQUIREMENTS,
  password1: '',
  password2: '',
  code: ''
}
export const defaultSettings = freeze({
  authAllowed: {},
  activeOrgId: null
})
export const defaultUser = {
  id: '',
  tabs,
  age: 0,
  tabProps: {},
  settings: defaultSettings,
  handle: '',
  name: '',
  verified: false,
  dataTypes: {},
  emails: [],
  phones: [],
  tagsD: {},
  data: [],
  factors: [],
  subscriptions: [],
  toggles: {},
  // used during resetting passwords
  passform: defaultPassForm,
  _normal: false
}

export function updateSettings(settings, setting) {
  return merge({}, settings, setting)
}

export function fallbackOrg(user) {
  return user?.orgMemberships
    ?.flatMap((m) => enrichPosixTime(m.org, 'updatedAt'))
    ?.sort((a, b) => intcmp(b._updatedAt, a._updatedAt))?.[0]
}

function normalizeUserData(state, value) {
  state.dataTypes = (value || []).reduce((a, d) => {
    a[d.type] = d
    return a
  }, {})
  state.toggles = state.dataTypes?.toggles?.value || {}
  return state
}

export function normalizeUserTags(state, value) {
  state.tagsD = (value || []).reduce((acc, tag) => {
    const current = acc[tag.type] || []
    // collapse usermap and tag
    acc[tag.type] = current.concat({
      ...tag,
      ...tag.tag,
      user_tag_id: tag.id
    })
    return acc
  }, {})
  return state
}

function normalizeUserEmails(state, emails) {
  if (emails && emails.length > 0) {
    return { ...state, emails, ...normalizeUserParam('emails', emails) }
  }
  return state
}

export function normalizeUser(state, update = {}) {
  let user = { ...state, ...update }
  user = normalizeUserTags(user, user.tags)
  user = normalizeUserData(user, user.data)
  user = normalizeUserEmails(user, user.emails)
  user.tabProps = {
    toggles: user.toggles
  }
  user.passform = { ...defaultPassForm }
  user.age = (Date.now() - new Date(user.insertedAt).getTime()) / 86400000
  user._normal = true
  return user
}

export function getUserData(user, group, token) {
  const grp = user.dataTypes[group] || { value: { [token]: '' } }
  return grp.value[token]
}

export function changeUserData(state, value, group, token) {
  const dataTypes = state.dataTypes
  const gdata = dataTypes[group] || { value: { [token]: '' } }

  return {
    ...state,
    dataTypes: {
      ...dataTypes,
      [group]: {
        ...gdata,
        value: {
          ...gdata.value,
          [token]: value
        }
      }
    }
  }
}

export function changePassForm(state, args) {
  const { merge, done } = args
  if (merge) {
    const passform = { ...state.passform, ...merge }
    return { ...state, passform }
  } else if (done) {
    return { ...state, passform: { ...defaultPassForm, done } }
  } else {
    throw new Error('unknown passform change')
  }
}
