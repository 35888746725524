import React, { useContext } from 'react'

import { GoogleLogin } from '@react-oauth/google'
import config from 'config'
import { defer } from 'lodash'

import GlobalContext from 'reducer/global'

import { R_SIGNON } from '../../utils/signon'
import { doSignonFederated } from './doSignonFederated'

export function SignonFederated() {
  const [state, dispatch] = useContext(GlobalContext)
  const providers =
    config?.auth?.federated?.map((provider) => provider.toLowerCase().trim()) ??
    []
  return (
    <div className="flex-center flex-wrap gv2">
      {providers.map((provider) => {
        switch (provider) {
          case 'google': {
            return (
              <GoogleLogin
                key={provider}
                onSuccess={(credentialResponse) => {
                  dispatch({ type: R_SIGNON.SIGNING_IN })
                  defer(doSignonFederated, {
                    state,
                    type: 'google',
                    authResponse: credentialResponse.credential, // intentionally undecoded JWT
                    dispatch
                  })
                }}
                onError={() => {
                  dispatch({ type: R_SIGNON.SIGN_OUT })
                }}
              />
            )
          }
          default:
            return null
        }
      })}
    </div>
  )
}

export default SignonFederated
