import React, { useContext, useState } from 'react'

import { R_SIGNON } from 'utils/signon'

import GlobalContext from 'reducer/global'

import { doSignonLocal } from './doSignonLocal'
import { REQUIREMENTS, badPassword } from './password'

////////////////////////////////////////////////////////////////////////////////
function checkPassword(pass1, pass2, signup, dispatch) {
  pass1 = pass1.trim()
  if (!signup) {
    return pass1
  }
  const error = badPassword(pass1, pass2)
  dispatch({ type: R_SIGNON.ERROR, value: error })
  return pass1
}

export function SignonLocal({ signup, label }) {
  const [state, dispatch] = useContext(GlobalContext)
  const [password, setPass1] = useState('')
  const [pass2, setPass2] = useState('')
  const [handle, setHandle] = useState('')

  return (
    <div className="items-center">
      <input
        className="w-100 br2 db"
        placeholder="Your email"
        value={handle}
        onChange={(e) => setHandle(e.target.value.trim())}
      />
      {signup && <div className="i f2 mt3">{REQUIREMENTS}</div>}
      <input
        className="w-100 br2 mt3 db"
        type="password"
        placeholder={'Your ' + (signup ? 'desired ' : '') + 'password'}
        value={password}
        onChange={(e) =>
          setPass1(checkPassword(e.target.value, pass2, signup, dispatch))
        }
      />
      {signup ? (
        <input
          className="w-100 br2 mt3"
          type="password"
          placeholder={'Your desired password (repeated)'}
          value={pass2}
          onChange={(e) =>
            setPass2(checkPassword(e.target.value, password, signup, dispatch))
          }
        />
      ) : null}
      <div className="flex justify-around mt3 items-center">
        <button
          className="w-100 large"
          style={{ border: 0, margin: 0 }}
          disabled={signup && password !== pass2}
          onClick={(e) => {
            e.preventDefault()
            dispatch({ type: R_SIGNON.ERROR_CLEAR })
            doSignonLocal({
              state,
              signup,
              handle,
              password,
              dispatch
            })
          }}
        >
          <span className="label pl2">{label}</span>
        </button>
      </div>
    </div>
  )
}

export default SignonLocal
