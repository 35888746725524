import { useEffect, useRef } from 'react'

export function PassThrough({ children }) {
  return children
}

export function Nothing() {
  return null
}

export function useScrollTo(scroll) {
  const elem = useRef()
  useEffect(() => {
    if (scroll && elem.current !== undefined) {
      elem.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [elem, scroll])

  return elem
}
