// import { useContext, useEffect } from 'react'

// import { invert } from 'utils/color'

// import GlobalContext from 'reducer/global'

// https://stackoverflow.com/questions/31677451/how-to-select-div-text-on-button-click
// export function selectElem(ref) {
//   const elem = ref?.current
//   if (elem) {
//     const range = document.createRange()
//     range.selectNode(elem)
//     window.getSelection().empty()
//     window.getSelection().addRange(range)
//   }
// }

// export function onReturn(fxn) {
//   return ({ key }) => {
//     if (key === 'Enter') fxn()
//   }
// }

// export function colorCorrect(hexColor, base = 'theme-dark') {
//   return document.body.classList.contains(base) ? hexColor : invert(hexColor)
// }

// export function TrackTheme() {
//   const [state] = useContext(GlobalContext)
//   const theme = `theme-${state.page.theme || state.user.settings.theme || 'dark'}`
//   useEffect(() => {
//     document.body.classList.remove('theme-dark')
//     document.body.classList.remove('theme-light')
//     document.body.classList.add(theme)
//   }, [theme])
//   return null
// }

export function anythingIsSelected() {
  const selection = document.getSelection()
  return ['forward', 'backward'].includes(selection.direction)
}

/*
 * @doctests
 *
 * ```js
 * t.truthy(isJson('{}'))
 * t.truthy(isJson('{"cat":{"hat":"tin"}}'))
 * t.falsy(isJson('{hi there}'))
 * ```
 */
export function isJson(str) {
  try {
    JSON.parse(str)
    return true
  } catch (err) {
    return false
  }
}

function makeKey({ version, key }) {
  return key + '-' + version
}

export function setLocalData(version, key, data) {
  localStorage.setItem(makeKey({ version, key }), JSON.stringify(data))
}

export function getLocalData(version, key) {
  const data = localStorage.getItem(makeKey({ version, key }))
  if (data == null) return null
  try {
    return JSON.parse(data)
  } catch (err) {
    return null
  }
}

export function isBlurOutside(e) {
  return e.relatedTarget == null || !e.currentTarget.contains(e.relatedTarget)
}

export function isOffscreen(elem) {
  const bounds = elem?.getBoundingClientRect()

  if (!bounds) return false

  return bounds.left < 0 || bounds.right > document.body.clientWidth
}

export function targetValue(e) {
  return e.target.value
}

/*
 * @doctests
 *
 * ```js
 * const qs1 = '?flag=hello&flag=world&look=ma'
 * const qs2 = '?a=foo&a=bar&a=baz'
 * t.is(toggleQueryParam(qs1, 'flag', 'world'), 'flag=hello&look=ma')
 * t.is(toggleQueryParam(qs1, 'look'), 'flag=hello&flag=world')
 * t.is(toggleQueryParam(qs1, 'look', 'ma'), 'flag=hello&flag=world')
 * t.is(toggleQueryParam(qs1, 'look', 'nohands'), 'flag=hello&flag=world&look=ma&look=nohands')
 * t.is(toggleQueryParam(qs2, 'a'), '')
 * t.is(toggleQueryParam(qs2, 'a', 'bar'), 'a=foo&a=baz')
 * t.is(toggleQueryParam(qs2, 'a', 'buzz'), 'a=foo&a=bar&a=baz&a=buzz')
 * ```
 */
export function toggleQueryParam(q, name, value) {
  const searchParam = new URLSearchParams(q)

  if (searchParam.has(name, value)) {
    searchParam.delete(name, value)
  } else {
    searchParam.append(name, value)
  }

  return searchParam.toString()
}

/*
 * @doctests
 *
 * ```js
 * const qs1 = '?flag=hello&flag=world&look=ma'
 * const qs2 = '?a=foo&a=bar&a=baz'
 * t.is(clearQueryParam(qs1, 'flag'), 'look=ma')
 * t.is(clearQueryParam(qs1, 'look'), 'flag=hello&flag=world')
 * t.is(clearQueryParam(qs1, ['flag', 'look']), '')
 * t.is(clearQueryParam(qs2, 'a'), '')
 * t.is(clearQueryParam(qs2, 'x'), 'a=foo&a=bar&a=baz')
 * ```
 */
export function clearQueryParam(q, names) {
  const searchParam = new URLSearchParams(q)
  names = Array.isArray(names) ? names : Array.of(names)
  names.forEach((name) => {
    searchParam.delete(name)
  })
  return searchParam.toString()
}

/*
 * @doctests
 *
 * ```js
 * const qs1 = '?a=foo&a=bar&a=baz'
 * const qs2 = '?a=1&b=2'
 * t.is(setQueryParam(qs1, 'a', 'buz'), 'a=buz')
 * t.is(setQueryParam(qs1, 'b', 'bah'), 'a=foo&a=bar&a=baz&b=bah')
 * t.is(setQueryParam(qs1, 'a', ''), '')
 * t.is(setQueryParam(qs2, 'a', '2'), 'a=2&b=2')
 * t.is(setQueryParam(qs2, 'b', '2'), 'a=1&b=2')
 * t.is(setQueryParam(qs2, 'b', ''), 'a=1')
 * ```
 */
export function setQueryParam(q, name, value) {
  const searchParam = new URLSearchParams(q)
  if (value) {
    searchParam.set(name, value)
  } else {
    searchParam.delete(name)
  }
  return searchParam.toString()
}

export const freeze = Object.freeze

// borrowed from example at:
//
//   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
//
function freezeObj(obj, freezer, maxDepth) {
  for (const name of Reflect.ownKeys(obj)) {
    const value = obj[name]
    if (value && typeof value === 'object') {
      // max maxDepth
      if (maxDepth !== undefined && maxDepth > 0) {
        freezer(value, maxDepth - 1)
      } else {
        Object.freeze(value)
      }
    }
  }
  return Object.freeze(obj)
}

export function shallowFreeze(object) {
  return freezeObj(object, Object.freeze)
}

// dangerous because you can over-do it into things you didn't expect, like
// if window is included, or if you have cross-linked objects(recursive)
export function deepFreeze(object, maxDepth = undefined) {
  return freezeObj(object, deepFreeze, maxDepth)
}

/*
 * @doctests
 *
 * ```js
 * t.falsy(isGoodGqlVariable(null))
 * t.falsy(isGoodGqlVariable(undefined))
 * t.falsy(isGoodGqlVariable(''))
 * t.falsy(isGoodGqlVariable('  '))
 * t.falsy(isGoodGqlVariable([]))
 * t.truthy(isGoodGqlVariable('hello'))
 * t.truthy(isGoodGqlVariable({}))
 * t.truthy(isGoodGqlVariable(new Date()))
 * t.truthy(isGoodGqlVariable(34))
 * t.truthy(isGoodGqlVariable(0))
 * ```
 */
export function isGoodGqlVariable(v) {
  if (Array.isArray(v)) return v.length > 0
  if (typeof v === 'number' && isNaN(v)) return false
  if (v == null) return false
  if (typeof v === 'string') return v?.trim()?.length > 0
  return true
}
