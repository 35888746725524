import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useMutation } from '@apollo/client'

import notifier from 'tools/Notify'

import { REQUIREMENTS } from 'common/Signon/password'
import { CHANGE_PASSWORD } from 'common/User/graphql'
import { GlobalContext } from 'reducer/global'

import { PasswordContext, changePassword, savePassword } from './reducer'

function Password() {
  const [state, dispatch] = useContext(PasswordContext)
  const [{ user }, gDispatch] = useContext(GlobalContext)
  const history = useHistory()
  const changePass = (s, d, ev, key) => changePassword(s, d, ev, key)
  const changeCode = (s, d, ev, key) =>
    d({ key: 'code', value: { value: ev.target.value.trim() } })

  const [mutatePass] = useMutation(CHANGE_PASSWORD)
  useEffect(() => {
    if (state.signout) {
      notifier.info(
        gDispatch,
        'Password updated, signing out. Please sign in again'
      )
      dispatch({ signout: 'signout' })
      history.push('/signout')
    }
  }, [state.signout, dispatch, history, gDispatch])

  return (
    <div>
      <InputSecret
        label={user.isAuthN ? 'Current Password' : 'Reset Code'}
        name="code"
        onChange={changeCode}
      >
        {user.isAuthN && state.email.value && (
          <button
            tabIndex={-1}
            className="plain nowrap medium f3 ml3"
            onClick={() => state.requestReset(state.email.value, dispatch)}
          >
            Request Reset Code
          </button>
        )}
      </InputSecret>
      {state.code.message && <div className="mt3">{state.code.message}</div>}
      <InputSecret
        info={REQUIREMENTS}
        label="New Password"
        name="password"
        compare={true}
        onChange={changePass}
      />
      <button
        className="medium mt4 mr3 db"
        onClick={() => savePassword(state, dispatch, mutatePass, user.isAuthN)}
        disabled={!state.password.good}
      >
        Change
      </button>

      <div className="mt3">{state.password.message}</div>
    </div>
  )
}

export function InputSecret({
  label,
  info = undefined,
  name,
  compare = false,
  children,
  onChange
}) {
  const [state, dispatch] = useContext(PasswordContext)
  const item = state[name]

  return (
    <div
      className="mt4"
      onBlur={(ev) => {
        ev.stopPropagation()
        dispatch({ key: name, value: { show: false } })
      }}
    >
      <div className="b mb3">
        {label}
        <i
          className={`fas fa-${
            item.show ? 'eye-slash' : 'eye'
          } ml2 hover pointer`}
          onClick={() => dispatch({ key: name, value: { show: !item.show } })}
        />
      </div>
      {info && <div className="mb3 f2">{info}</div>}
      <div className="mb3 flex-items">
        <UniformSecret
          show={item.show}
          value={item.value}
          placeholder={label}
          onChange={(ev) => onChange(state, dispatch, ev, 'value')}
        />
        {children}
      </div>
      {compare && (
        <div className="mb3">
          <UniformSecret
            show={item.show}
            value={item.value2}
            placeholder={label + ' (again)'}
            onChange={(ev) => onChange(state, dispatch, ev, 'value2')}
          />
        </div>
      )}
    </div>
  )
}

export function UniformSecret({ show, value, placeholder, onChange }) {
  return (
    <input
      type={show ? 'text' : 'password'}
      className="w-100 w-50-l db"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  )
}

export default Password
