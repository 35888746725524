import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useQuery } from '@apollo/client'
import { debounce, defer } from 'lodash'

import Clipboard from 'tools/Clipboard'
import Modal from 'tools/Modal'
import { readableError } from 'tools/Notify'
import { prism } from 'utils/color'
import { getIn } from 'utils/dict'
import { isOffscreen } from 'utils/dom'
import { stopEvent } from 'utils/event'
import { noop } from 'utils/function'

import { useOnClickOutside } from 'reducer/global/hooks'

import { SECTIONS, SectionBoundary, SectionHeading } from './Sections'

export function AddButton({
  onClick,
  children = undefined,
  className = 'f7 w-100'
}) {
  return (
    <button className={`${className} clear large ph2 pv1`} onClick={onClick}>
      <i className="fas fa-plus mr2" />
      {children}
    </button>
  )
}

// like AddButton but wrapping the initial field and create
export const DEFAULT_EDIT = { open: false, error: undefined }

// split out valState so others can give their own for extra behavior
export function CreateButton(args) {
  const valState = useState(DEFAULT_EDIT)
  return <CreateButtonValState valState={valState} {...args} />
}

export function CreateButtonValState({
  onSave,
  valState,
  placeholder,
  children,
  label = undefined,
  className = undefined,
  ...args
}) {
  const [edit, setEdit] = valState
  const [input, setInput] = useState('')
  const submit = () => {
    onSave(
      args,
      input,
      () => {
        setEdit(DEFAULT_EDIT)
      },
      (reason) => {
        setEdit({ ...DEFAULT_EDIT, error: reason })
      }
    )
  }

  if (edit.open) {
    return (
      <>
        <div className={`flex-center ${className}`}>
          {label ? <label className="mr2">{label}:</label> : null}
          <input
            placeholder={placeholder}
            onChange={(ev) => setInput(ev.target.value)}
            onKeyDown={(ev) => {
              // @ts-ignore
              if (ev.key === 'Enter') {
                // @ts-ignore
                submit()
              }
            }}
            className="w-90 mr3"
            autoFocus={true}
          />
          <button
            className="plain large ml-auto"
            onClick={() => setEdit({ ...DEFAULT_EDIT, open: false })}
          >
            Cancel
          </button>
          <button className="large ml3" onClick={submit}>
            Create
          </button>
        </div>
        {edit.error ? <div className="red">{edit.error}</div> : null}
      </>
    )
  }
  return (
    <AddButton
      className={className}
      onClick={() => setEdit({ ...DEFAULT_EDIT, open: true })}
    >
      {children}
    </AddButton>
  )
}

export function AngleIcon({ open, className = '' }) {
  return <i className={`fas fa-angle-${open ? 'down' : 'right'} ${className}`} />
}

export function PivotIcon({ open, className = '', family = 'fas' }) {
  return (
    <i
      className={`${family} fa-${open ? 'minus' : 'plus'}-square ${className}`}
    />
  )
}

export function CheckIcon({ checked, className = '' }) {
  return (
    <i
      className={`${
        checked ? 'fas fa-square-check' : 'far fa-square'
      } ${className}`}
    />
  )
}

export function RadioIcon({ checked, className = '' }) {
  return (
    <i
      className={`${
        checked ? 'far fa-circle-dot' : 'far fa-circle'
      } ${className}`}
    />
  )
}

export function Label({ children, variant = undefined, className = '' }) {
  switch (variant) {
    // as in a table with the label left of the value
    case 'left':
      className += ' ttu tr justify-end'
      break
    // the rest are the label above the value
    case 'large':
      className += ' mt4 mb3 f6'
      break
    case 'medium':
      className += ' mv3 f5'
      break
    case 'small':
      className += ' mb3'
      break
    default:
      className = className || 'mt4 mb3'
  }
  return <label className={`db flex-items accent ${className}`}>{children}</label>
}

export function Page({ className = '', children }) {
  return <div className={`mt4 mt5-l mb6 mh3 mh0-l ${className}`}>{children}</div>
}

// TabFrame when in a tabbed from
export function TabFrame({ children, className = 'pa4' }) {
  return (
    <div className={`max-view-page base-frame mb5 ${className}`}>{children}</div>
  )
}

// MaxViewPage outside of a tabbed frame
export function MaxViewPage({ className = '', children }) {
  return (
    <div className={`max-view-page mt4 mt5-l mb6 mh3 mh0-l ${className}`}>
      {children}
    </div>
  )
}

const ELLIPSIS_STYLE = {
  lineBreak: 'anywhere',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden'
}

export function StretchEllipsisText({
  children,
  text,
  className = '',
  clipboard = true
}) {
  return (
    <span className="flex items-center">
      <span title={text || children} className={className} style={ELLIPSIS_STYLE}>
        {text || children}
      </span>
      {clipboard && <Clipboard value={text || children} />}
    </span>
  )
}

export function Hero({ title, children }) {
  return (
    <div className="base-body invert">
      <div
        className="flex-center flex-column hmin7"
        style={{
          backgroundImage:
            "url('https://cato.digital/assets/img/hero/cato-pricing-hero-2.webp')",
          backgroundSize: 'cover'
        }}
      >
        <div className="max-view-page w-100 ph3 ph0-l">
          <h2 className="f8 fw3">{title}</h2>
          <div className="lh-copy mt2 w-50-l">{children}</div>
        </div>
      </div>
    </div>
  )
}

export function Search({
  onChange, // make sure onChange uses useCallback!
  value = undefined,
  setValue = undefined,
  className = 'mb3',
  placeholder = 'search',
  autoFocus = false,
  wait = 300,
  type = 'search',
  onReturn = noop,
  ...args
}) {
  const change = useMemo(() => debounce(onChange, wait), [onChange, wait])
  const autoFocusFn = useCallback(
    (element) => {
      if (autoFocus && element) {
        defer(() => element.focus())
      }
    },
    [autoFocus]
  )

  return (
    <input
      ref={autoFocusFn}
      value={value}
      className={className}
      type={type}
      spellCheck={false}
      placeholder={placeholder}
      onKeyDown={({ key }) => {
        if (key === 'Enter') {
          onReturn()
        }
      }}
      onChange={(e) => {
        const value = e.target.value
        setValue?.(value)
        change(value)
      }}
      {...args}
    />
  )
}

export function SelectThing({
  query,
  label,
  resultsPath,
  field,
  fetchPolicy = 'cache-first',
  stateTuple: [thingId, setThingId]
}) {
  const [open, setOpen] = useState(false)
  const { data, error } = useQuery(query, {
    fetchPolicy,
    skip: !open
  })

  const things = getIn(data, resultsPath) ?? []

  return (
    <>
      <button
        className="clear flex items-center gh2 ttc"
        onClick={() => setOpen(!open)}
      >
        {label}
        <i className={`fas fa-caret-${open ? 'down' : 'right'}`} />
      </button>
      <div className="flex flex-column items-start gv1 ml3">
        {things.map(({ id, ...thing }) => (
          <button
            key={id}
            className="clear flex items-center gh2 f2"
            onClick={() => {
              setThingId(id === thingId ? undefined : id)
            }}
          >
            <input type="radio" checked={id === thingId} readOnly={true} />
            <div>{thing[field]}</div>
          </button>
        ))}
      </div>
      <div>{error && readableError(error)}</div>
    </>
  )
}

export function Divider({ children = <>&nbsp;</> }) {
  return <div className="flex-center f1 base-accent mv3 w-100">{children}</div>
}

export function RadioButtons({
  options,
  onChange,
  active = undefined,
  className = 'flex ga1',
  buttonClass = (isActive) => (isActive ? '' : 'clear')
}) {
  return (
    <div className={className}>
      {options.map(({ label, value }) => (
        <button
          key={value}
          onClick={onChange.bind(null, value)}
          className={buttonClass(active === value)}
        >
          {label}
        </button>
      ))}
    </div>
  )
}

/*
 * notes: make sure onClose is memoized
 * example usage:
  <div> { showError && (
    <ExpiringMessage duration={10_000} onClose={clearError}>
      info about your {error} here
    </ExpiringMessage>
    )
  } </div>
 */
export function ExpiringMessage({ duration, onClose, children }) {
  useEffect(() => {
    let isMounted = true
    setTimeout(() => {
      if (isMounted) {
        onClose()
      }
    }, duration)

    return () => {
      isMounted = false
    }
  }, [duration, onClose])

  return children
}

const defaultHelpIcon = (
  <i className="fas pa1 br3 fa-question-circle light-silver pointer" />
)
export function Help({
  children,
  title = '',
  className = '',
  icon = defaultHelpIcon,
  stopEvents = true
}) {
  const [show, setShow] = useState(false)

  return (
    <>
      <span
        className={className}
        onClick={(e) => {
          stopEvents && stopEvent(e)
          setShow(true)
        }}
      >
        {icon}
      </span>
      {show ? (
        <Modal
          viewState={[show, setShow]}
          width="fw-min"
          header={title}
          className="lh-copy pa4"
          stopEvents={stopEvents}
        >
          {children}
        </Modal>
      ) : null}
    </>
  )
}

function RainbowFacility({ shortId }) {
  const rainbow = prism(shortId)
  const className = `r${rainbow}`
  return <span className={className}>{shortId}</span>
}

export function locationString({
  facility, // facilityShortId
  zone, // zoneCode
  pod, // podCode
  rack, // rackPosition
  port // portPosition
}) {
  if (facility && zone && pod && rack && port) {
    return `${facility}-${zone}${pod}-r${rack}${port}`
  }

  if (facility && zone && pod) {
    return `${facility}-${zone}${pod}`
  }

  if (zone && pod && rack && port) {
    return `${zone}${pod}-r${rack}${port}`
  }

  if (rack && port) {
    return `r${rack}${port}`
  }

  if (facility && port) {
    return `${facility}:${port}`
  }

  if (port) {
    return `${port}`
  }

  return '—'
}

export function LocationLabel({
  facility, // facilityShortId
  zone, // zoneCode
  pod, // podCode
  rack, // rackPosition
  port // portPosition
}) {
  if (facility && zone && pod && rack && port) {
    return (
      <span className="nowrap">
        <RainbowFacility shortId={facility} />-{zone}
        {pod}-r{rack}
        {port}
      </span>
    )
  }

  if (facility && zone && pod) {
    return (
      <span className="nowrap">
        <RainbowFacility shortId={facility} />-{zone}
        {pod}
      </span>
    )
  }

  if (zone && pod && rack && port) {
    return (
      <span className="nowrap">
        {zone}
        {pod}-r{rack}
        {port}
      </span>
    )
  }

  if (rack && port) {
    return (
      <span className="nowrap">
        r{rack}
        {port}
      </span>
    )
  }

  if (facility && port) {
    return (
      <span className="nowrap">
        <RainbowFacility shortId={facility} />:{port}
      </span>
    )
  }

  if (port) {
    return <span>{port}</span>
  }

  return <>—</>
}

export function Code({ children, text, className = '' }) {
  const inline = children || text
  return (
    <div className={className}>
      <Clipboard
        value={text}
        className="user-select-text neutral tl w-100 relative"
      >
        <code className="pre-wrap pv2 pl2 pr3 mr1 br2 db">{inline}</code>
        <i
          className="f3 far fa-copy gray absolute"
          style={{ top: 'calc(50% - 8px)', right: '2px' }}
        />
      </Clipboard>
    </div>
  )
}

export function ControlledMenuButton({
  showState: [show, setShow],
  label,
  children,
  className = ''
}) {
  const parentRef = useRef(null)
  const innerRef = useRef(null)
  const close = useCallback(() => setShow(false), [setShow])
  const toggle = () => {
    setShow(!show)
    defer(() => {
      const elem = innerRef.current
      if (isOffscreen(elem)) {
        elem.classList.add('right-0')
      }
      elem?.classList?.remove('hidden')
    })
  }

  useOnClickOutside(parentRef.current, close)

  return (
    <div className="relative" ref={parentRef}>
      <button className={className} onClick={toggle}>
        {label}
      </button>
      {show && (
        <div ref={innerRef} className="z-1 absolute normal hidden">
          <button className="absolute right-0 clear small" onClick={close}>
            <i className="fa fa-times" />
          </button>
          {children}
        </div>
      )}
    </div>
  )
}

export { SECTIONS, SectionHeading, SectionBoundary }
