// import Affiliate from './Affiliate'
// import Founder from './Founder'
// import Investor from './Investor'
// import Provider from './Provider'
// import Show from './Show'
import { makeTab } from 'tools/Nav/NavTabs'

import Account from './Account'
import Profile from './Profile'
import { DeleteConfirm } from './Profile/Delete'

export default [
  makeTab({
    id: 'profile',
    name: 'Profile',
    Component: Profile,
    props: { className: 'w-100', editing: true }
  }),
  // makeTab({
  //   id: 'subs',
  //   name: 'Subscriptions',
  //   Component: EmailPrefs
  // }),
  makeTab({
    id: 'password',
    name: 'Password',
    Component: Account
  }),
  // makeTab({
  //   id: 'founder',
  //   name: 'Founder',
  //   Component: Founder,
  //   // TODO: data migration of toggles.contributor => toggles.founder
  //   view: ({ props }) => props?.toggles?.founder === true
  // }),
  // makeTab({
  //   id: 'investor',
  //   name: 'Investor',
  //   view: ({ props }) => props?.toggles?.investor === true,
  //   Component: Investor
  // }),
  // makeTab({
  //   id: 'affiliate',
  //   name: 'Affiliate',
  //   view: ({ props }) => props?.toggles?.affiliate === true,
  //   Component: Affiliate
  // }),
  // makeTab({
  //   id: 'provider',
  //   name: 'Provider',
  //   view: ({ props }) => props?.toggles?.provider === true,
  //   Component: Provider
  //   // props: { className: 'w-100', editing: true }
  // }),
  makeTab({
    id: 'delete',
    name: 'DELETE ACCOUNT',
    view: (x) => false,
    Component: DeleteConfirm,
    hideTabs: true
  })
  // makeTab({
  //   id: 'pwreset',
  //   name: 'Reset Password',
  //   view: (x) => false,
  //   Component: ResetPassword,
  //   hideTabs: true
  // })
  // makeTab({
  //   id: 'verify',
  //   name: 'Verify Email',
  //   view: (x) => false,
  //   Component: VerifyEmail
  // })

  // makeTab({
  //   id: 'show',
  //   name: 'Show',
  //   orig: true,
  //   view: () => false,
  //   hideTabs: true,
  //   Component: Show
  // }),
]
