import startup from 'startup'

import Root from 'hub/Root'
import Router from 'hub/Root/Router'

startup(
  // these are merged into constants/config, so override as appropriate
  {
    name: 'Cato Metal Console'
  },
  Router,
  // these are passed into <Router {...props} />
  { Root }
)
