import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useMutation } from '@apollo/client'
import config from 'config'
import { debounce } from 'lodash'

import { LoadingCenter } from 'tools/Loading'
import { readableError } from 'tools/Notify'
import { toDictOn } from 'utils/array'
import { useScrollTo } from 'utils/react'
import { handleOneResponse } from 'utils/response'
import { R_SIGNON } from 'utils/signon'
import { doVerifyEmailCode } from 'utils/user'

import { UPDATE_USER } from 'common/User/graphql'
import paths from 'common/paths'
import GlobalContext from 'reducer/global'

import SignonFederated from './SignonFederated'
import SignonLocal from './SignonLocal'
import { TERMS } from './password'

////////////////////////////////////////////////////////////////////////////////
function Heading() {
  return (
    <div className="pt2 pt4-ns">
      <div className="pa3 flex-center">
        <div className="pa3 f6 lh-copy fw5 tc">
          {config.logo.full ? (
            <img
              src={config.logo.full}
              style={{ height: '12rem' }}
              className="mr3"
              alt={config.name}
            />
          ) : (
            config.name
          )}
        </div>
      </div>
    </div>
  )
}

const TAB_HEADERS = [
  { txt: 'Sign in', tok: 'signin' },
  { txt: 'Sign up', tok: 'signup' },
  { txt: 'Verify', tok: 'verify' }
]
const TAB_HEADERS_MAP = toDictOn(TAB_HEADERS, 'tok')

function ResendEmailCode({ userId, emailId }) {
  const [, dispatch] = useContext(GlobalContext)
  const [changeUser] = useMutation(UPDATE_USER)

  const onSave = useMemo(
    () =>
      debounce((e) => {
        e.preventDefault()
        dispatch({ type: R_SIGNON.ERROR_CLEAR })
        changeUser({
          variables: {
            id: userId,
            email: {
              id: emailId,
              verify: true
            }
          }
        })
          .then(handleOneResponse('data', 'updateUser'))
          .then(() => {
            dispatch({
              type: R_SIGNON.ERROR,
              value: (
                <b className="hilite">
                  Sent! Please check your inbox and spam folders
                </b>
              )
            })
          })
          .catch((err) => {
            console.error('error requesting code', { err })
            dispatch({
              type: R_SIGNON.ERROR,
              value: 'Unable to send! Please try again later or contact support'
            })
          })
      }, 500),
    [changeUser, userId, emailId, dispatch]
  )

  return (
    <div>
      Not seeing the code?
      <button className="dib clear" onClick={onSave}>
        Resend
      </button>
    </div>
  )
}

function VerifyEmailCode({ address }) {
  const [, dispatch] = useContext(GlobalContext)
  const [code, setCode] = useState('')
  const onSave = () => {
    if (code.trim().length === 0) {
      console.log(code, 'code')
      dispatch({ type: R_SIGNON.ERROR, value: 'Please provide a code.' })
      return null
    }
    doVerifyEmailCode(code)
      .then((email) => {
        if (email.length > 0) {
          dispatch({ type: R_SIGNON.RELOAD_USER })
        }
        if (email !== address) {
          dispatch({
            type: R_SIGNON.ERROR,
            value: 'Code does not match email address.'
          })
        }
      })
      .catch((err) => {
        dispatch({ type: R_SIGNON.ERROR, value: readableError(err) })
      })
  }

  return (
    <div className="flex flex-column gv3">
      <input
        type="text"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder="code from email"
        autoFocus={true}
      />
      <button className="large" onClick={onSave}>
        <span className="label pl2">Verify &amp; Signup</span>
      </button>
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function SignonInline({
  signup = false,
  children = undefined,
  heading = true,
  scroll = false
}) {
  const [currentTab, setCurrentTab] = useState(signup ? 'signup' : 'signin')
  const boxPadding = 'ph3 ph4-ns'
  const [
    {
      user,
      signon: { error }
    },
    dispatch
  ] = useContext(GlobalContext)

  const elemView = useScrollTo(scroll)

  const tabClick = useCallback(
    (tok, event) => {
      event?.preventDefault()
      dispatch({ type: R_SIGNON.ERROR_CLEAR })
      setCurrentTab(tok)
    },
    [dispatch]
  )

  const { emailAddress, emailId, showVerifiedTab } = useMemo(() => {
    const { address, id, verified } = user?.primaryEmail ?? {}
    return {
      emailAddress: address,
      emailId: id,
      showVerifiedTab: address && !verified
    }
  }, [user])

  useEffect(() => {
    if (showVerifiedTab) {
      tabClick('verify')
    }
  }, [showVerifiedTab, tabClick])

  return (
    <div className={'flex-center ' + (heading ? 'mb6 mb0-ns mt6' : '')}>
      <div>
        <div ref={elemView} />
        {heading && <Heading />}
        {children}
        {user.signingIn ? <LoadingCenter /> : null}
        <div className="base-frame flex-center mb5 mh3 mh0-l wmax8-l wmin8-l">
          <div className="w-100">
            <div className="flex" style={{ columnGap: '2px' }}>
              {TAB_HEADERS.map((item, index) => {
                if (item.tok === 'verify' && !showVerifiedTab) return null
                return (
                  <button
                    onClick={(e) => tabClick(item.tok, e)}
                    className={`bn b pa2 tc w-50 br0 f5 ${
                      item.tok !== currentTab ? 'neutral' : 'clear'
                    } hover-hilight`}
                    key={index}
                    style={{ borderRadius: 0 }}
                  >
                    {item.txt}
                  </button>
                )
              })}
            </div>
            {currentTab === 'verify' ? (
              <div className="ph3 flex pt3 flex-column gv2 mw6 center">
                <div className="tc mv4">
                  Please enter the code sent to <b>{emailAddress}</b>
                </div>
                <div className="mt2">
                  <VerifyEmailCode address={emailAddress} />
                </div>
                <div className="mv2 tc f3">
                  <ResendEmailCode userId={user.id} emailId={emailId} />
                </div>
              </div>
            ) : (
              <>
                {config.auth.federated && (
                  <div
                    className={`${boxPadding} items-center ba b--transparent pb1 pt3 pt4-ns`}
                  >
                    <div className="primary mb3 tc w-100 f2 b">Sign in with:</div>
                    <SignonFederated />
                    {config.auth.local && (
                      <div className="hrstrike pt3">
                        <div className="f2 primary b">or</div>
                      </div>
                    )}
                  </div>
                )}
                {config.auth.local && (
                  <div className={`${boxPadding} pb1 mt3`}>
                    <SignonLocal
                      signup={currentTab === 'signup'}
                      label={TAB_HEADERS_MAP[currentTab].txt}
                    />
                  </div>
                )}
              </>
            )}
            <div className={`${boxPadding} tc fw2`}>
              {error && <div className="visible pa2 mt2 red">{error}</div>}
            </div>
            <div className={`${boxPadding} items-center ba b--transparent`}>
              <div className="f2 tc mv3 i lh-copy ph3">
                <b>By continuing, you agree</b> {TERMS}
              </div>
              {config.auth.local && (
                <div className="i f2 tc mv3">
                  <a href={paths.public.password}>Forgot Password?</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignonInline
