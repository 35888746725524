import React from 'react'
import { createRoot } from 'react-dom/client'

import config from 'config'

import { freeze } from 'utils/dom'

import { SignonProvider } from 'common/Signon/SignonProvider'
import { GlobalProvider } from 'reducer/global'

import 'styles/index.scss'

export default async function startup(conf, Router, props) {
  console.log(`BUILD=${config.build} APP=${config.app}`)

  Object.assign(config, conf)
  freeze(config)

  const container = document.getElementById('root')
  const root = createRoot(container)

  // only include high level providers and configuration things here
  root.render(
    <GlobalProvider>
      <SignonProvider>
        <Router {...props} />
      </SignonProvider>
    </GlobalProvider>
  )
}
