import { identity } from './function.js'
import { intcmp, strcmp } from './string.js'

// .js required for doctests

/*
 * using lists for ordered sets
 * @doctests
 *
 * ```js
 * t.deepEqual(setAdd([1, 2, 3], 2), [ 1, 2, 3 ])
 * t.deepEqual(setAdd([1, 2, 3], 4), [ 1, 2, 3, 4 ])
 * ```
 */
export function setAdd(list1, component) {
  if (!list1.includes(component)) {
    list1.push(component)
  }
  return list1
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(setMerge([1, 2, 3], [2, 3, 4]), [ 1, 2, 3, 4 ])
 * ```
 */
export function setMerge(list1, list2) {
  list2.forEach((elem) => setAdd(list1, elem))
  return list1
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(removeItem([{id: 1}, {id: 2}, {id: 3}], {id: 2}), [ {id: 1}, {id: 3} ])
 * ```
 */
export function removeItem(list, item) {
  return list.filter((i) => i.id !== item.id)
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(upsertItem([{id: 1}, {id: 2}, {id: 3}], {id: 2}), [ {id: 1}, {id: 2}, {id: 3} ])
 * t.deepEqual(upsertItem([{id: 1}, {id: 2}], {id: 3}), [ {id: 1}, {id: 2}, {id: 3} ])
 * ```
 */
export function upsertItem(list, item) {
  const hasItem = list.some(({ id }) => id === item.id)
  return hasItem
    ? list.map((x) => (x.id === item.id ? item : x))
    : list.concat(item)
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(range(0, 4, 1), [ 0, 1, 2, 3 ])
 * t.deepEqual(range(0, 4), [ 0, 1, 2, 3 ])
 * t.deepEqual(range(0, 4, 2), [ 0, 2 ])
 * t.deepEqual(range(0, 5, 2), [ 0, 2, 4 ])
 * t.deepEqual(range(1, 1), [])
 * t.deepEqual(range(1, 0), [])
 * t.deepEqual(range(1, -Infinity), [])
 * ```
 */
export function range(start, stop, step = 1) {
  if (stop < start) return []
  return Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(groupBy([1,1,3,3,3,1]), [ [ 1, 1 ], [ 3, 3, 3 ], [ 1 ] ])
 * t.deepEqual(
 *  groupBy([{'a': 1, 'b':1}, {'a': 1, 'b':2}, {'a': 1,'b':3}], (a,b) => a.a === b.a),
 *  [ [ { a: 1, b: 1 }, { a: 1, b: 2 }, { a: 1, b: 3 } ] ]
 * )
 * ```
 * note: use lodash groupBy if you want global grouping
 */
export function groupBy(iter, fxn = (a, b) => a === b) {
  return iter.reduceRight((grouped, val) => {
    if (!grouped.length) {
      return [[val]]
    }
    if (fxn(grouped[0][0], val)) {
      grouped[0].unshift(val)
    } else {
      grouped.unshift([val])
    }
    return grouped
  }, [])
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(
 *  groupOn([{'a': 1, 'b':1}, {'a': 1, 'b':2}, {'a': 1,'b':3}], 'a'),
 *  [ [ { a: 1, b: 1 }, { a: 1, b: 2 }, { a: 1, b: 3 } ] ]
 * )
 * ```
 */
export function groupOn(iter, key) {
  return groupBy(iter, (a, b) => a[key] === b[key])
}

/*
 * Behavior:
 *    removes items from start of list until predicate is false
 *    then returns rest of list
 *
 * @doctests
 *
 * ```js
 * t.deepEqual(dropWhile(x => x < 5, [1,2,3,4,5,6,7,8,9]), [5,6,7,8,9])
 * t.deepEqual(dropWhile(x => x === 0, [0,0,1,0,0]), [1,0,0])
 * ```
 */
export function dropWhile(predicate, iter) {
  const [, result] = iter.reduce(
    ([cont, acc], val) => {
      if (cont) {
        acc.push(val)
        return [true, acc]
      }
      if (predicate(val)) {
        return [false, acc]
      }
      acc.push(val)
      return [true, acc]
    },
    [false, []]
  )
  return result
}

/*
 * Behavior:
 *    Same as dropWhile but works right to left
 *
 * @doctests
 *
 * ```js
 * t.deepEqual(dropWhileRight(x => x > 5, [1,2,3,4,5,6,7,8,9]), [1, 2, 3, 4, 5])
 * t.deepEqual(dropWhileRight(x => x === 0, [0,0,1,0,0]), [0,0, 1])
 * t.deepEqual(dropWhileRight(x => x === '_', ['a', '_', 'b', '_', '_', '_']), ['a', '_', 'b'])
 * ```
 */
export function dropWhileRight(predicate, iter) {
  const [, result] = iter.reduceRight(
    ([cont, acc], val) => {
      if (cont) {
        acc.unshift(val)
        return [true, acc]
      }
      if (predicate(val)) {
        return [false, acc]
      }
      acc.unshift(val)
      return [true, acc]
    },
    [false, []]
  )
  return result
}

// don't use for sorting on non-string keys
export const naturalSortBy = (fxn) => (a, b) => {
  return strcmp(fxn(a), fxn(b))
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual([{name: "cat10"}, {name: "cat2"}].sort(naturalSortOn('name')), [{name: "cat2"}, {name: "cat10"}])
 * ```
 */
export const naturalSortOn = (key) => naturalSortBy((obj) => obj[key])

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(['10', '1', '03', '23', '9', '34'].sort(naturalSort), ['1', '03', '9', '10', '23', '34'])
 * t.deepEqual(['cat10', 'cat2'].sort(naturalSort), ['cat2', 'cat10'])
 * ```
 */
export const naturalSort = naturalSortBy((x) => x)

/*
 * @doctests
 *
 * ```js
 * t.is(last([1, 2, 3, 4]), 4)
 * t.is(last([3, 1]), 1)
 * t.is(last([]), undefined)
 * t.is(last({}), undefined)
 * t.is(last(null), undefined)
 * ```
 */
export const last = (lst) =>
  Array.isArray(lst) ? lst[lst.length - 1] : undefined

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(toDictOn([{id: 'a', val: 1}, {id: 'b', val: 2}, {id: 'c', val: 3}], 'id'), {a: {id: 'a', val: 1}, b: {id: 'b', val: 2}, c: {id: 'c', val: 3}})
 * t.deepEqual(toDictOn([{id: 'a', val: 1}, {id: 'b', val: 2}, {id: 'c', val: 3}], 'id', 'val'), {a: 1, b: 2, c: 3})
 * t.deepEqual(toDictOn([{id: 'a', val: 1}, {id: 'b', val: 2}, {id: 'c', val: 3}], 'id', ({val}) => val), {a: 1, b: 2, c: 3})
 * t.deepEqual(toDictOn([{id: 'a', val: 1}, {id: 'b', val: 2}, {id: 'c', val: 3}], 'id', {}), {a: {id: 'a', val: 1}, b: {id: 'b', val: 2}, c: {id: 'c', val: 3}})
 * ```
 */
export const toDictOn = (arr, key, transform = undefined, dict = {}) => {
  let fxn = (() => {
    switch (typeof transform) {
      case 'function':
        return transform
      case 'string':
        return (obj) => obj[transform]
      case 'undefined':
        return identity
      default: {
        console.warn(
          `toDictOn given ${typeof transform}, expected undefined, string, or function`
        )
        return identity
      }
    }
  })()
  return arr.reduce((acc, obj) => {
    acc[obj[key]] = fxn(obj)
    return acc
  }, dict)
}

/*
 * @doctests
 *
 * ```js
 * var arr = [{id: 'a'}, {id: 'b'}, {id: 'c'}]
 * var item = {id: 'a', val: '!'}
 * var expected = [{id: 'a', val: '!'}, {id: 'b'}, {id: 'c'}]
 * t.deepEqual(replaceOrAdd(arr, item, (a, b) => a.id === b.id), expected)
 * var item2 = {id: 'd'}
 * var expected2 = [{id: 'a'}, {id: 'b'}, {id: 'c'}, {id: 'd'}]
 * t.deepEqual(replaceOrAdd(arr, item2, (a, b) => a.id === b.id), expected2)
 * ```
 */
export const replaceOrAdd = (arr, item, eq = (a, b) => a === b) => {
  const alreadyHas = arr.some((i) => eq(i, item))
  if (alreadyHas) {
    return arr.map((i) => (eq(i, item) ? item : i))
  }
  return arr.concat(item)
}

/*
 * @doctests
 *
 * ```js
 * var arr = [1,2,3,4,5]
 * var expected = [1, 0, 2, 0, 3, 0, 4, 0, 5]
 * t.deepEqual(intersperse(arr, 0), expected)
 * ```
 */
export const intersperse = (xs, a) => xs.flatMap((x) => [x, a]).slice(0, -1)

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(head([1,2]), 1)
 * t.deepEqual(head([]), undefined)
 * t.deepEqual(head(["a"]), "a")
 * ```
 */
export const head = (xs) => xs[0]

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(toggleMembership(1, [1,2]), [2])
 * t.deepEqual(toggleMembership(4, [2, 3]), [2, 3, 4])
 * t.deepEqual(toggleMembership(4, [2, 3, 4, 4, 4]), [2, 3])
 * ```
 */
export const toggleMembership = (x, xs) => {
  const xs2 = xs.filter((val) => val !== x)
  return xs.length === xs2.length ? xs.concat(x) : xs2
}

/*
 * naive implementation don't use for large lists
 *
 * @doctests
 *
 * ```js
 * t.deepEqual(dedupe([]), [])
 * t.deepEqual(dedupe([1,2]), [1,2])
 * t.deepEqual(dedupe([1,2,2]), [1,2])
 * t.deepEqual(dedupe([2,1,2,4,1]), [2,1,4])
 * ```
 */
export const dedupe = (xs) => {
  if (xs.length <= 1) return xs
  return xs.slice(0, 1).concat(dedupe(xs.slice(1).filter((x) => x !== xs[0])))
}

/*
 * @doctests
 *
 * ```js
 *
 * t.deepEqual(splice([1,2,3,4,5], 1, 1), [1,3,4,5])
 * t.deepEqual(splice([1,2,3,4,5], 0, 2), [3,4,5])
 * t.deepEqual(splice([1,2,3,4,5], 3, 10), [1,2,3])
 * t.deepEqual(splice([1,2,3,4,5], 2, 1, 5), [1,2,5,4,5])
 * ```
 */
export function splice(elems, start, deleteCount, ...items) {
  return elems
    .slice(0, start)
    .concat(items)
    .concat(elems.slice(start + deleteCount))
}

/*
 * @doctests
 *
 * ```js
 * t.is(commonElems("cat", ""), 0)
 * t.is(commonElems("cat", "c"), 1)
 * t.is(commonElems("cat", "ca"), 2)
 * t.is(commonElems("cat", "cat"), 3)
 * ```
 */
export function commonElems(arr1, arr2, count = 0) {
  const [h1, ...t1] = arr1
  const [h2, ...t2] = arr2
  return h1 !== h2 || h1 === undefined || h2 === undefined
    ? count
    : 1 + commonElems(t1, t2, count)
}

/*
 * @doctests
 *
 * ```js
 * let input = ['1234', '12345', '124545']
 * let output = { "1234": "1234", "12345": "12345", "124545": "124"}
 * t.deepEqual(toShortCodeMap(input), output)
 * input = ['1234']
 * output = { "1234": "1" }
 * t.deepEqual(toShortCodeMap(input), output)
 * input = []
 * output = {}
 * t.deepEqual(toShortCodeMap(input), output)
 * ```
 */
export function toShortCodeMap(uuids = []) {
  if (uuids.length === 1) {
    const [uuid] = uuids
    return { [uuid]: uuid[0] }
  }
  const getShortUuid = (uuid, uuids) =>
    uuid.slice(0, 1 + Math.max(...uuids.map((id) => commonElems(uuid, id))))
  return Object.fromEntries(
    uuids.map((uuid, i) => [uuid, getShortUuid(uuid, splice(uuids, i, 1))])
  )
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(reverse([1,2,3]), [3,2,1])
 * ```
 */
export function reverse(arr) {
  return arr.reduceRight((acc, item) => {
    acc.push(item)
    return acc
  }, [])
}

/*
 * @doctests
 *
 * ```js
 * t.is(getAt([1,2,3], 1), 2)
 * t.is(getAt([1,2,3], 3), 1)
 * ```
 */
export function getAt(arr, index) {
  return arr[index % arr.length]
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(circularQueuePush([1,2], 3, 4), [1,2,4])
 * t.deepEqual(circularQueuePush([1,2], 2, 4), [2,4])
 * t.deepEqual(circularQueuePush([1,2,3,4,5,6,7], 2, 8), [7,8])
 * ```
 */
export function circularQueuePush(arr, size, val) {
  return [...arr, val].slice(arr.length + 1 - size, arr.length + 1)
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(partition((x) => x < 4, [1,9,2,8,3,7]), [[1,2,3], [9,8,7]])
 * ```
 */
export function partition(fxn, arr) {
  const part = [[], []]
  arr.forEach((x) => {
    part[+!fxn(x)].push(x)
  })
  return part
}

/*
 * @doctests
 *
 * ```js
 * const items = [
 *  { id: 1, order: 1 },
 *  { id: 2, order: 2 },
 *  { id: 3, order: 3 },
 *  { id: 4, order: 4 },
 *  { id: 5, order: 5 },
 * ]
 * const expected = [
 *  { id: 1, order: 1 },
 *  { id: 2, order: 4 },
 *  { id: 3, order: 2 },
 *  { id: 4, order: 3 },
 *  { id: 5, order: 5 },
 * ]
 * t.deepEqual(reposition(items, 2, 4), expected)
 * ```
 */
export function reposition(items, id, order, key = 'id') {
  const oldOrder = items.find((item) => item[key] === id).order
  order = Math.max(1, order)
  return items.map((item) => {
    if (item[key] === id) return { ...item, order }
    if (order < oldOrder) {
      if (item.order >= order && item.order < oldOrder) {
        return { ...item, order: item.order + 1 }
      }
    } else if (item.order <= order && item.order > oldOrder) {
      return { ...item, order: item.order - 1 }
    }
    return item
  })
}

/*
 * @doctests
 *
 * ```js
 * const items = [
 *  { name: 'cat' },
 *  { name: 'mouse' },
 *  { name: 'dog' },
 * ]
 * const expected1 = [
 *  { name: 'mouse' },
 *  { name: 'cat' },
 *  { name: 'dog' },
 * ]
 * const expected2 = [
 *  { name: 'cat' },
 *  { name: 'dog' },
 *  { name: 'mouse' },
 * ]
 * const expected3 = [
 *  { name: 'mouse' },
 *  { name: 'dog' },
 *  { name: 'cat' },
 * ]
 * const expected4 = [
 *  { name: 'dog' },
 *  { name: 'cat' },
 *  { name: 'mouse' },
 * ]
 * t.deepEqual(moveItem(items, 'mouse', 'cat', 'name'), expected1)
 * t.deepEqual(moveItem(items, 'mouse', 'dog', 'name'), expected2)
 * t.deepEqual(moveItem(items, 'cat', 'dog', 'name'), expected3)
 * t.deepEqual(moveItem(items, 'dog', 'cat', 'name'), expected4)
 * ```
 */
export function moveItem(items, srcName, destName, key = 'id') {
  if (srcName == null || destName == null || srcName === destName) return items
  const destOrder = 1 + items.findIndex((item) => item[key] === destName)
  if (destOrder < 1) return items
  console.assert(
    !items.some((item) => item?.order != null),
    'items cannot already have an "order" field!'
  )
  return reposition(
    items.map((item, index) => ({ order: index + 1, ...item })),
    srcName,
    destOrder,
    key
  )
    .sort((a1, a2) => intcmp(a1.order, a2.order))
    .map(({ order, ...item }) => item)
}

/*
 * @doctests
 *
 * ```js
 * t.is(matchIndex(1, [0,1,2], ['a', 'b', 'c']), 'b')
 * ```
 */
export function matchIndex(x, xs, ys) {
  return getAt(ys, xs.indexOf(x))
}

/*
 * @doctests
 *
 * ```js
 * const xs = [1,5,7,6,-1,7,3]
 * t.deepEqual(sort(xs, (a, b) => a < b, xs), [-1,1,3,5,6,7,7])
 * t.deepEqual(sort(xs, (a, b) => a > b, xs), [7,7,6,5,3,1,-1])
 * ```
 */
export function sort(arr, ordered) {
  // ordered (a: T, b: T) : boolean
  const [head, ...tail] = arr
  if (head == null) return []
  const [left, right] = partition((val) => ordered(val, head), tail)
  return sort(left, ordered).concat(head).concat(sort(right, ordered))
}

/*
 * @doctests
 *
 * ```js
 * const arr = [1,2,3,4,5,6,7]
 * t.is(findNAfter(arr, (x => x === 4)), 5)
 * t.is(findNAfter(arr, (x => x === 3), 2), 5)
 * t.is(findNAfter(arr, (x => x === 7)), undefined)
 * t.is(findNAfter(arr, (x => x === 7), -1), 6)
 * ```
 */
export function findNAfter(arr, predicate, n = 1) {
  return arr.find((_, index) => {
    const elem = arr[index - n]
    if (!elem) return false
    return predicate(elem, index, arr)
  })
}

export function toArray(arr) {
  return Array.isArray(arr) ? arr : [arr]
}
