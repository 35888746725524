import React from 'react'
import Switch from 'react-switch'

export function Toggle({
  value = false,
  onChange = (v) => {},
  children = undefined,
  className = '',
  mv = 'mv2',
  pl = 'pl2',
  pr = '',
  flow = 'right',
  ...props
}) {
  return (
    <>
      <div
        className={`${mv} ${pl} ${pr} flex items-center pointer hover-hilight br3 ${className}`}
        onClick={() => onChange(!value)}
        {...props}
      >
        {children && (flow === 'top' || flow === 'left') && <div>{children}</div>}
        <Switch
          onChange={() => {}}
          checked={value}
          // so frustrating it wants hex and can't even use var(---)
          // reading the project comments, they dissect the hex and do color
          // interpolation between stages. Such a tiny "feature" and yet
          // it creates so much pain for having to have this individually
          // defined outside of our theme/style sheets.
          onColor="#51dc41"
          offColor="#3c5956"
          height={12}
          width={30}
          className="mr2"
          activeBoxShadow="0 0 2px 3px white"
        />
        {children && flow === 'right' && <div>{children}</div>}
      </div>
    </>
  )
}

export function ToggleDict({
  settings,
  onChange,
  children,
  keyword,
  subkey,
  invert
}) {
  let setdict = settings
  if (subkey) {
    if (!settings[subkey]) {
      settings[subkey] = {}
    }
    setdict = settings[subkey]
  }

  // invert so it's on by default, even if not set
  const value = invert ? setdict[keyword] : !setdict[keyword]

  return (
    <Toggle
      value={value}
      onChange={(a) => {
        console.log('<TOGGLE Dict>', a)
        const val = !value
        if (invert ? val : !val) {
          setdict[keyword] = true
        } else {
          delete setdict[keyword]
        }
        onChange(setdict)
      }}
    >
      {children}
    </Toggle>
  )
}

export default Toggle
