import React, { useContext, useState } from 'react'

import { useQuery } from '@apollo/client'
import config from 'config'
import { merge } from 'lodash'

import Loading from 'tools/Loading'
import Toggle from 'tools/Toggle'
import { TabFrame } from 'tools/Uniform'

import ChangePassword from 'common/User/ChangePassword'
import { MY_FACTORS } from 'common/User/graphql'
import GlobalContext from 'reducer/global'

import UserContext from '../reducer'

// import DeleteAccount from './DeleteAccount'

function Account() {
  const [factors, setFactors] = useState(undefined)
  const [{ user: actor }] = useContext(GlobalContext)
  const [user] = useContext(UserContext)

  useQuery(MY_FACTORS, {
    variables: { id: user.id },
    skip: !user.id,
    onCompleted({ users }) {
      if (users.success && users.results.length === 1) {
        const target = users.results[0] || {}
        setFactors(
          Object.fromEntries(target.factors?.map((f) => [f.type, true]) ?? [])
        )
      } else {
        console.log('Problem loading factors', { users })
      }
    }
  })
  const allowed = actor.settings.authAllowed || {}

  return (
    <TabFrame className="pa0">
      <AdminStripe ready={factors} user={user} editor={actor}>
        <FederatedAuthPrefs />
        <ChangePassword
          email={user?.primaryEmail?.address}
          factors={factors}
          allowed={allowed}
        />
      </AdminStripe>
    </TabFrame>
  )
}
// <DeleteAccount />

export function AdminStripe({ ready = true, user, editor, children }) {
  if (!user?._loaded || !ready) {
    return (
      <div className="w-100 vhmin-75">
        <Loading />
        &nbsp;
      </div>
    )
  } else {
    return (
      <>
        {user.id !== editor.id && (
          <div className="w-100 ph4 pv2 f2 bg-yellow">
            Administrative Edit of {user.name} ({user.primaryEmail?.address})
          </div>
        )}
        <div className="pa4">{children}</div>
      </>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
function FederatedAuthPrefs() {
  const [user] = useContext(UserContext)
  if (!config.auth?.federated) {
    return null
  }
  return (
    <>
      <label className="f3 b db mb3 heading">Sign in Preference</label>
      <div className="flex-items pl2 mb4">
        <Toggle
          onChange={() => {
            const settings = merge(user.settings, {
              authAllowed: { google: !user.settings?.authAllowed?.google }
            })

            user.onSave(
              { token: 'settings', component: 'user', componentId: false },
              JSON.stringify(settings)
            )
          }}
          value={user.settings?.authAllowed?.google}
        >
          Allow Google Authentication (using any of the emails on this account)
        </Toggle>
      </div>
    </>
  )
}

export default Account
