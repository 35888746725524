import React, { useState } from 'react'

import Modal from 'tools/Modal'

export function Confirm({
  onConfirm,
  children = undefined,
  title = 'Are you sure?',
  good = true,
  viewState
}) {
  return (
    <Modal
      viewState={viewState}
      width="fw-min"
      className="pa4"
      wrapClass="overflow-y-auto"
      header={title}
    >
      <div className="mt3">{children}</div>
      <div className="mt3 flex justify-between">
        <button
          className={`large ${good ? 'neutral' : ''}`}
          onClick={() => viewState[1](false)}
        >
          No
        </button>
        <button
          className={`large : ${good ? '' : 'neutral'}`}
          onClick={onConfirm}
        >
          Yes
        </button>
      </div>
    </Modal>
  )
}

export function Delete({
  className = 'pa1 f3',
  label = '',
  labelIcon = 'fas fa-trash',
  onConfirm,
  children,
  title = undefined
}) {
  const [drop, setDrop] = useState(false)
  return (
    <>
      <button
        className={className}
        onClick={(ev) => {
          ev.stopPropagation()
          ev.preventDefault()
          setDrop(!drop)
        }}
      >
        {labelIcon && <i className={`${labelIcon} ${label ? 'mr2' : ''}`} />}
        {label}
      </button>
      {drop ? (
        <Confirm
          viewState={[drop, setDrop]}
          onConfirm={() => {
            onConfirm()
            setDrop(false)
          }}
          title={title}
        >
          {children}
        </Confirm>
      ) : null}
    </>
  )
}

export function CloseableModal({
  children = undefined,
  title = '',
  closeText = 'Close',
  viewState
}) {
  const [, setOpen] = viewState
  return (
    <Modal
      viewState={viewState}
      width="fw-50"
      noScroll={true}
      className="base-frame pa4"
    >
      <div className="f3 b">{title}</div>
      <div className="mt3">{children}</div>
      <div className="mt3 flex justify-start">
        <button className="large plain" onClick={() => setOpen(false)}>
          {closeText}
        </button>
      </div>
    </Modal>
  )
}

export function ConfirmPassState({
  onConfirm,
  children = undefined,
  title = '',
  rejectText = 'No',
  confirmText = 'Yes',
  viewState
}) {
  const [, setOpen] = viewState
  return (
    <Modal
      viewState={viewState}
      width="fw-50"
      noScroll={true}
      className="base-frame pa4"
    >
      <div className="f3 b">{title || 'Are you sure?'}</div>
      <div className="mt3">{children}</div>
      <div className="mt3 flex justify-between">
        <button className="large border" onClick={() => setOpen(false)}>
          {rejectText}
        </button>
        <button
          className="large"
          onClick={() => {
            onConfirm()
            setOpen(false)
          }}
        >
          {confirmText}
        </button>
      </div>
    </Modal>
  )
}

export function ConfirmWithState({
  label,
  onConfirm,
  className = '',
  hoverHide = true,
  ...props
}) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div
        className={`${className ? className : 'ph1'} ${
          hoverHide ? 'hover-show-elem' : ''
        }`}
        onClick={(e) => {
          setOpen(true)
          // hrm, why is this still propagating, grr
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {label}
      </div>
      {open ? (
        <ConfirmPassState
          {...props}
          onConfirm={onConfirm}
          viewState={[open, setOpen]}
        />
      ) : null}
    </>
  )
}

export default ConfirmWithState
