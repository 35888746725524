import React from 'react'

import { Label } from 'tools/Uniform'

export default function InputLabel({
  maxlen,
  value,
  dirty,
  label,
  labelClass,
  help,
  helpClass
}) {
  return (
    <Label className={labelClass || ''}>
      {label}
      {maxlen && <Showlength value={value} max={maxlen} dirty={dirty} />}
    </Label>
  )
}

function Showlength({ value, max, dirty }) {
  if (!max || !dirty) {
    return null
  }
  return (
    <div className={`f2 normal ml2 i ${value?.length === max ? '' : 'gray'}`}>
      ({value.length} of {max})
    </div>
  )
}
