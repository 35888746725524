import React, { createContext, useReducer } from 'react'

import { R_NOTIFY } from 'tools/Notify/reducer'
import { naturalSortOn } from 'utils/array'
import { freeze } from 'utils/dom'
import { R_SIGNON } from 'utils/signon'
import { enrichPosixTime } from 'utils/time'

import {
  BASE_R_GLOBAL,
  baseGlobalReducer,
  generateBaseGlobalState,
  resetBaseGlobalState
} from 'reducer/global/base'

const defaultHubState = freeze({
  org: {
    id: null
  }
})

const defaultState = generateBaseGlobalState(defaultHubState)

function resetGlobalState(oldState, seeds) {
  return resetBaseGlobalState(oldState, seeds || defaultState)
}

const R_GLOBAL = freeze({
  ...BASE_R_GLOBAL,
  // start local addons after 10k+
  ORG_MERGE: 10000
})

function normalizeUserOrg(indata) {
  const orgMemberships = indata.orgMemberships
    .map((om) => ({ ...om, org: enrichPosixTime(om.org, 'updatedAt') }))
    .sort(naturalSortOn('name'))

  const org = indata.activeOrg ?? {}

  const isMemberOfActiveOrg =
    org.id && orgMemberships.some((member) => member.org.id === org.id)

  return {
    ...indata,
    orgMemberships,
    isMemberOfActiveOrg,
    org
  }
}

function reducer(state, args) {
  switch (args.type) {
    case R_GLOBAL.ORG_MERGE:
      return { ...state, org: { ...state.org, ...args.value } }

    // catch this for the org, before passing it back to the base reducer
    case R_GLOBAL.USER_SET:
      const value = normalizeUserOrg(args.value)
      const org = value.org ?? {}
      state = { ...state, org }
      return baseGlobalReducer(state, { ...args, value })

    default:
      return baseGlobalReducer(state, args)
  }
}

const GlobalContext = createContext(null)
function GlobalProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, defaultState)
  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export {
  R_GLOBAL,
  R_SIGNON,
  R_NOTIFY,
  GlobalProvider,
  resetGlobalState,
  GlobalContext
}
