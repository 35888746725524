import React, { useContext } from 'react'

import notifier from 'tools/Notify'

function Clipboard({
  value,
  type = 'button',
  label = undefined,
  show = false,
  className = 'user-select-text clear nowrap',
  children = <i className="f3 far fa-copy gray" />
}) {
  const [, notifyDispatch] = useContext(notifier.Context)
  const clipboard = navigator.clipboard
  if (!clipboard) return null
  if (!value) return null
  return React.createElement(
    type,
    {
      className,
      onClick: () => {
        let vshow = <> value to clipboard</>
        if (show) {
          vshow = (
            <>
              : <code className="b">{value}</code>
            </>
          )
        }
        clipboard
          .writeText(value)
          .then(() => {
            notifier.info(notifyDispatch, label ?? <>Copied{vshow}</>)
          })
          .catch(notifier.onCatch(notifyDispatch))
      }
    },
    children
  )
}

export default Clipboard
