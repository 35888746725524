import React, { useCallback, useContext } from 'react'

import { PasswordContext, message } from './reducer'

function EmailInput() {
  const [state, dispatch] = useContext(PasswordContext)

  const requestCode = useCallback((email, dispatch, request) => {
    if (email.length > 0 && email.includes('@')) {
      // dispatch({result: <LoadingInline />})
      request(email, dispatch)
    } else {
      message(dispatch, 'code', 'needEmail')
    }
  }, [])

  return (
    <>
      <div className="mt4 mb3">
        <label>Password Reset</label>
      </div>
      <div className="mb3">
        Provide an email address you registered with. If it is in our system, we
        will send a reset request to that address.
      </div>
      <input
        className="w-100 w-50-l db mb3"
        value={state.email.value}
        placeholder="Email Address"
        onChange={(ev) =>
          dispatch({ key: 'email', value: { value: ev.target.value.trim() } })
        }
      />
      <button
        className="button medium nowrap mt3"
        onClick={() =>
          requestCode(state.email.value, dispatch, state.requestReset)
        }
      >
        Request Reset Code
      </button>
      {state.code.message && <div className="mt3 b"> {state.code.message}</div>}
    </>
  )
}

export default EmailInput
