import React, { useContext, useMemo } from 'react'

import notifier from 'tools/Notify'
import { plural, unsnakeCase } from 'utils/string'
import { ROLES, isAuthZ } from 'utils/user'

import { NoticeBell } from 'common/User/Notices'
import paths from 'hub/paths'
import GlobalContext from 'reducer/global'

import { ManageMenu, SiteMenu } from './Menus'
import menus from './Menus.config'

export function NotificationBadge() {
  const [
    {
      notify: { navbar: navNotify }
    }
  ] = useContext(notifier.Context)

  if (!navNotify) return null

  return (
    <div className="flex-center ml-auto f3 i truncate">{navNotify.content}</div>
  )
}

function NavBar() {
  const [
    {
      user,
      page: { hideChrome, innerNav, title, NavOverride }
    }
  ] = useContext(GlobalContext)

  if (hideChrome) {
    return null
  }

  if (NavOverride) {
    return <NavOverride signedIn={user.isIdentified} />
  }

  return (
    <>
      <div className="base-navbar navbar w-100 pv1 flex items-stretch ph3">
        {user.isIdentified && (
          <div className="flex-center">
            <ManageMenu />
          </div>
        )}
        <div className="flex justify-between items-stretch flex-grow-1">
          {innerNav ? (
            <innerNav.Component {...innerNav.args} />
          ) : (
            title && <div className="b f3 flex-center flex-grow-1">{title}</div>
          )}
          <NotificationBadge />
        </div>
        <div className="flex-center">
          <div className="pl2 pr1">
            <NoticeBell />
          </div>
          <SiteMenu signedIn={user.isIdentified} />
        </div>
      </div>
      <AdminBar />
    </>
  )
}

function WarnStrip({ children, className = '' }) {
  return (
    <div
      className={`fixed w-100 base-warn tc f1 ${className}`}
      style={{ top: 'var(--navbar-height)', paddingBottom: '0.12rem' }}
    >
      {children}
    </div>
  )
}

const PATHS_WITH_ADMIN_BAR = [paths.public.org, paths.public.compute]
function AdminBar() {
  const [
    {
      user,
      org,
      page: { section }
    }
  ] = useContext(GlobalContext)
  const s = useMemo(() => menus.manageSections(org, section), [org, section])

  // forget admin warning, if we're out of date just show that
  if (window.VERSION_OUTDATED) {
    return (
      <WarnStrip className="">
        <div className="pa2 f3 flex-center gh2">
          There is a new version of Cato Console. Please refresh your browser
          window:
          <button
            onClick={() => window.location.reload()}
            className="button clear"
          >
            Reload
          </button>
        </div>
      </WarnStrip>
    )
  }

  const roles = user?.roles
  const numRoles = roles?.size ?? 0
  const roleOrRoles = plural(numRoles, 'role')
  const isAdmin = isAuthZ(user, { roles: [ROLES.HUB_MANAGER, ROLES.HUB_OPS] })

  if (
    user.isIdentified &&
    isAdmin &&
    PATHS_WITH_ADMIN_BAR.some((p) => s?.to?.startsWith(p))
  ) {
    const roles = Array.from(user.roles).map(unsnakeCase).join(', ')
    return (
      <WarnStrip>
        You are an admin with {roleOrRoles}: {roles}
      </WarnStrip>
    )
  }

  return null
}

export default NavBar
