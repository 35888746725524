import React, { useContext, useMemo } from 'react'
import { MenuButton, MenuItem, MenuList } from 'react-menu-list'

import { NavLink } from 'tools/Link'
import { isAuthZ } from 'utils/user'

import GlobalContext from 'reducer/global'

import menus from './Menus.config'

function AuthzMenuItem({
  item: {
    label,
    id = undefined,
    to = undefined,
    external = undefined,
    divider = false,
    miniLabel = false,
    action = undefined,
    actions = undefined,
    role = undefined,
    roles = undefined,
    Linker = NavLink
  },
  user,
  org
}) {
  const authed = isAuthZ(user, { action, actions, role, roles })
  if (!authed) return null

  if (miniLabel) {
    return (
      <div key={id} className="f1 ml3 ttu gray" style={{ marginTop: '-0.4rem' }}>
        {authed && <i className="mr2 f1 fas fa-key" />}
        {miniLabel}
      </div>
    )
  } else if (divider) {
    return <div key={id} className="bt b--moon-gray mv2" />
  } else {
    return (
      <MenuItem>
        <Linker to={to} className="menu-item">
          <div className="relative f3">{label}</div>
        </Linker>
      </MenuItem>
    )
  }
}

function Menu({ menu, children }) {
  const [{ user, org }] = useContext(GlobalContext)

  return (
    <MenuButton
      menu={
        <div className="base-navbar navmenu z-2">
          <MenuList>
            {menu.map((item) => (
              <AuthzMenuItem
                item={item}
                user={user}
                org={org}
                key={item.id || item.to}
              />
            ))}
          </MenuList>
        </div>
      }
    >
      {children}
    </MenuButton>
  )
}

const CORPIMG = (
  <img
    className="db"
    style={{ width: '4rem' }}
    src="https://cato.digital/assets/img/cato-logo1-light-alpha.svg"
    alt="Cato Digital"
  />
)

export function ManageMenu() {
  const [
    {
      page: { section },
      org
    }
  ] = useContext(GlobalContext)

  const { s, menu } = useMemo(() => {
    const s = menus.manageSections(org, section)
    const menu = menus.manage(org)
    return { s, menu }
  }, [org, section])

  return (
    <Menu menu={menu}>
      <div className="pa1 flex items-center">
        <img src="/favicon.ico" style={{ height: '1rem' }} alt="C" />
        <span className="pl2 pr1">
          {s && section ? section : CORPIMG}
          {s?.displayOrgName && org.name && (
            <span className="ml2 fw4">({org.name})</span>
          )}
        </span>
        <i className="fas fa-angle-down fa-xs ml1" />
      </div>
    </Menu>
  )
}

export function SiteMenu({ signedIn }) {
  if (signedIn) {
    return (
      <Menu menu={menus.hamburger}>
        <i className="pv2 ph1 f3 fa fa-bars" />
      </Menu>
    )
  }
  return (
    <NavLink to="/signon" className="fw5 f3 ph3 pv1 border">
      Sign in
    </NavLink>
  )
}
