import React from 'react'
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'

import { ControlIcon, Controls } from 'tools/Controls'
import { stopEvent } from 'utils/event'
import { noop } from 'utils/function'

//import Scrollbar from './Scrollbar'
import style from './index.module.scss'

function MyModal({
  width = 'fw-min',
  viewState: [modalOpen, setModalOpen],
  header = undefined,
  children = undefined,
  //noScroll = false,
  className = '',
  wrapClass = '',
  stopEvents = true,
  onAfterClose = noop
}) {
  // TODO: in the future address ariaHideApp
  // warn msg:
  //    Warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`. This is needed so screen readers don't see main content when modal is opened. It is not recommended, but you can opt-out by setting `ariaHideApp={false}`.

  const widthStyle = width ? style[width] : style['fw-50']

  if (modalOpen) {
    return (
      <div onClick={(e) => stopEvents && stopEvent(e)}>
        <Modal
          isOpen={!!modalOpen}
          ariaHideApp={false}
          onRequestClose={() => setModalOpen(false)}
          className={style.modal}
          overlayClassName={style.overlay}
          onAfterClose={onAfterClose}
        >
          <div
            className={`base-frame shadow-6 ${style.frame} ${widthStyle} ${style.modal} ${wrapClass}`}
          >
            {header ? (
              <div className={`base-accent b flex-items ph3 ${style.header}`}>
                {header}
              </div>
            ) : null}
            <div
              className={`${style.close} pa1 flex-center br2 hover-hilight`}
              onClick={() => setModalOpen(false)}
            >
              <i className="fas fa-times b" />
            </div>
            <InnerModal className={className}>{children}</InnerModal>
          </div>
        </Modal>
      </div>
    )
  } else {
    return null
  }
}

function InnerModal({ children, className }) {
  return <div className={`${className ?? style.body} w-100`}>{children}</div>
}
export function InlineModal({
  header = undefined,
  className = '',
  back = '', // if unspecified, then use history.goBack()
  controls = null,
  hideHeader = false,
  hideControls = false,
  onClose = undefined,
  vPad = 'mt3-ns mt4-l mb6',
  children,
  styles = {}
}) {
  const history = useHistory()
  return (
    <div className={`mh1-ns mh3-m mh4-l ${vPad} flex-l max-view-page`}>
      <div className="base-frame w-100 relative" style={styles}>
        {hideHeader ? null : (
          <div
            className={`base-accent ph2 ph3-l tl f3 b flex justify-between items-center ${style.header} ttu`}
          >
            {header}
          </div>
        )}
        {hideControls ? null : (
          <Controls>
            {controls}
            <ControlIcon
              icon="fas fa-times"
              onClick={() =>
                onClose ? onClose() : back ? history.push(back) : history.goBack()
              }
            />
          </Controls>
        )}
        <div className={className}>{children}</div>
      </div>
    </div>
  )
}

export default MyModal
