import React from 'react'
import { MenuButton, MenuItem, MenuList } from 'react-menu-list'

import sitemap from 'sitemap.json'

import { ManageMenu, SiteMenu } from './Menus'

export function corpSiteLink(link) {
  return 'https://cato.digital' + link
}

function SitemapNav({ signedIn }) {
  return (
    <div className="base-navbar navbar f3 fw4 sticky top-0 left-0 right-0 pa3">
      <div className="flex-between h-100 fw4">
        {signedIn ? <ManageMenu /> : <div />}
        <div className="flex-center gh2 ">
          {sitemap.map((item) => (
            <SitemapMenu signedIn={signedIn} item={item} key={item.label} />
          ))}
          <SiteMenu signedIn={signedIn} />
        </div>
      </div>
    </div>
  )
}

function SitemapMenu({ item, signedIn }) {
  if (item.label === 'Get Started') {
    return (
      <div className="menu b" key={item.label}>
        <button className="active ph3 pv2" onClick={() => {}}>
          Get Started
        </button>
      </div>
    )
  } else {
    return (
      <MenuButton
        key={item.label}
        className="ph3 pv2"
        menu={
          item.menu && (
            <div className="base-navbar navmenu">
              <MenuList>
                {item.menu.map((sub) => {
                  return (
                    <MenuItem key={sub.label}>
                      <a
                        className="menu-item nowrap"
                        href={corpSiteLink(sub.link)}
                      >
                        {sub.label}
                      </a>
                    </MenuItem>
                  )
                })}
              </MenuList>
            </div>
          )
        }
      >
        {item.label}
      </MenuButton>
    )
  }
}

export default SitemapNav
