export const prefix = '/sys'

export const paths = {
  sys: {
    dev: `${prefix}/dev`,
    devRoute: `${prefix}/dev/:view?/:targetId?`
  },
  public: {
    messages: '/messages',
    password: '/password/',
    passwordRoute: '/password/:targetId?/:code?',
    signon: '/signon/:up?',
    signout: '/signout',
    user: '/user',
    userRoute: '/user/:targetId?/:view?/:code?'
  }
}

export default paths
