import React, { useContext, useState } from 'react'

import Input from 'tools/Input'
import { doVerifyEmailCode } from 'utils/user'

import GlobalContext, { R_SIGNON } from 'reducer/global'

function EditEmail({ user }) {
  const deletable = user.emails.length > 1

  return (
    <div className="w-100 ba br2 mb2 input pa2">
      {user.emails.map((email) => (
        <ShowEmail
          key={email.id}
          email={email}
          deletable={deletable}
          user={user}
        />
      ))}
      <AddEmail user={user} />
    </div>
  )
}

function ShowEmail({ email, deletable, user }) {
  const [check, setCheck] = useState(false)
  return (
    <div
      key={email.id}
      className="menu-item flex justify-between items-center mb2"
    >
      <code>{email.address}</code>
      <div className={`${email.verified ? 'green' : 'warn'} ml2 mr-auto`}>
        {email.verified ? (
          'verified'
        ) : (
          <div className="flex items-center gh2">
            <button
              className="f2 pa0 ph2 medium"
              onClick={() => {
                user.onSave(
                  {
                    component: 'email',
                    cmeta: { verify: true },
                    token: 'id',
                    componentId: false
                  },
                  email.id
                )
                setCheck(true)
              }}
            >
              resend verify email
            </button>
            {check && <i className="green fas fa-check" />}
            <div className="flex-center gh2">
              <VerifyEmailCode address={email.address} />
            </div>
          </div>
        )}
      </div>
      {deletable ? (
        <i
          className="fas fa-trash pa1 br0 pointer fa-lg"
          onClick={() =>
            user.onSave(
              {
                component: 'email',
                meta: { action: 'REMOVE' },
                token: 'id',
                componentId: false
              },
              email.id
            )
          }
        />
      ) : null}
    </div>
  )
}

function VerifyEmailCode({
  address,
  className = 'mw4',
  placeholder = 'code from email',
  ...args
}) {
  const [, dispatch] = useContext(GlobalContext)
  const [value, setValue] = useState('')

  return (
    <Input
      wrapClass={className}
      placeholder={placeholder}
      saveButton={true}
      {...args}
      value={value}
      onChange={setValue}
      onSave={(meta, code, good, bad) => {
        doVerifyEmailCode(code)
          .then((email) => {
            if (email.length > 0) {
              dispatch({ type: R_SIGNON.RELOAD_USER })
            }
            if (email === address) {
              good()
            } else {
              bad('Code does not match email')
            }
          })
          .catch((err) => bad(err))
      }}
    />
  )
}

function AddEmail({ user }) {
  const [adding, setAdding] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [msg, setMsg] = useState('')

  if (adding) {
    return (
      <>
        <div className="flex-items">
          <input
            className="w-100"
            value={newEmail}
            autoFocus={true}
            placeholder="New Email Address"
            onChange={(ev) => {
              setNewEmail(ev.target.value.trim())
            }}
          />
          <button
            className="pa2 ml2 ph4"
            onClick={() =>
              user.onSave(
                { component: 'email', componentId: false, token: 'email' },
                newEmail,
                () => {
                  setAdding(false)
                  setNewEmail('')
                  setMsg('')
                },
                (m) => {
                  setMsg(m)
                }
              )
            }
          >
            Add
          </button>
        </div>
        {msg ? <div className="mt3 red">{msg}</div> : null}
      </>
    )
  }
  return (
    <button className="f3 pa2 mb0 mt1 ph4 tl" onClick={() => setAdding(true)}>
      <i className="fas fa-plus mr2" />
      Add Email
    </button>
  )
}

export default EditEmail
