import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { validate as isUuid } from 'uuid'

import notifier from 'tools/Notify'
import * as dict from 'utils/dict'
import {
  clearQueryParam,
  isGoodGqlVariable,
  setQueryParam,
  toggleQueryParam
} from 'utils/dom'
import { handleOneResponse } from 'utils/response'
import { getAccessToken } from 'utils/signon'
import { userToActiveOrgId } from 'utils/user'

//import { intcmp } from 'utils/string'
//import { enrichPosixTime } from 'utils/time'
import { LOAD_USER, UPDATE_USER } from 'common/User/graphql'
import { fallbackOrg, updateSettings } from 'common/User/normalize'
import GlobalContext, {
  R_GLOBAL //, assertActiveOrg
} from 'reducer/global'

// export function missingDeps(deps) {
//   return !Array.isArray(deps)
// }

export function usePage(pageData, deps) {
  // if (missingDeps(deps)) {
  //   console.trace('usePage missing deps')
  //   deps = []
  // }
  const [, dispatch] = useContext(GlobalContext)
  useEffect(() => {
    dispatch({
      type: R_GLOBAL.PAGE_SET,
      value: pageData
    })
  }, [dispatch, ...deps]) // eslint-disable-line react-hooks/exhaustive-deps
}

////////////////////////////////////////////////////////////////////////////////
export function useStateDebounce(value, wait = 300) {
  const [match, setMatch] = useState(value)
  const onInput = useMemo(() => debounce(setMatch, wait), [setMatch, wait])
  return [match, onInput]
}

export function useSearchQuery(QUERY, options = {}, wait = 300) {
  const { variables = {} } = options
  const [match, onInput] = useStateDebounce('', wait)

  const result = useQuery(QUERY, {
    ...options,
    variables: {
      ...variables,
      matching: match
    }
  })

  return { ...result, onInput }
}
// returns URLSearchParams object with the current query parameters
export function useQueryParams() {
  const location = useLocation()
  return useMemo(() => new URLSearchParams(location.search), [location])
}

export function useSearchFilters(toVariables = undefined, meta = undefined) {
  const location = useLocation()
  const history = useHistory()
  const filter = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const url = location.pathname + '?'
    const variables =
      typeof toVariables === 'function'
        ? dict.filter(toVariables(params, meta), ([_, v]) => isGoodGqlVariable(v))
        : {}
    return {
      get: params.get.bind(params),
      getAll: params.getAll.bind(params),
      has: params.has.bind(params),
      push: history.push.bind(history),
      url,
      setTo(name, value) {
        return url + setQueryParam(params, name, value)
      },
      clearTo(names) {
        return url + clearQueryParam(params, names)
      },
      toggleTo(name, value = undefined) {
        return url + toggleQueryParam(params, name, value)
      },
      variables
    }
  }, [history, location, toVariables, meta])
  return filter
}

export function useUrlFragment() {
  const location = useLocation()
  return location.hash.replace('#', '')
}

export function useFeature(flag) {
  const qp = useQueryParams()
  return qp.getAll('flag').includes(flag)
}

export function useOnClickOutside(parent, onClickOutside) {
  useEffect(() => {
    const onClick = ({ target }) => {
      if (parent == null) return null
      if (parent.contains(target) === false) {
        onClickOutside(target)
      }
    }
    document.addEventListener('click', onClick)
    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [parent, onClickOutside])

  return null
}

////////////////////////////////////////////////////////////////////////////////
export function windowPreferredTheme() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'theme-dark'
    : 'theme-light'
}

export function isThemeDark() {
  return document.body.classList.contains('theme-dark')
}

export function useTheme() {
  // const [state, dispatch] = useContext(GlobalContext)
  //
  // useEffect(() => {
  //   if (window.matchMedia) {
  //     if (window.matchMedia('(prefers-color-scheme: light)').matches) {
  //       dispatch({ type: R_GLOBAL.PAGE_SET, value: { theme: 'theme-light' } })
  //     }
  //     window
  //       .matchMedia('(prefers-color-scheme: dark)')
  //       .addEventListener('change', (e) => {
  //         dispatch({
  //           type: R_GLOBAL.PAGE_SET,
  //           value: { theme: e.matches ? 'theme-dark' : 'theme-light' }
  //         })
  //       })
  //   }
  // }, [dispatch])
  //
  // const theme =
  //   state.page.theme || state.user.settings.theme || windowPreferredTheme()

  useEffect(() => {
    document.body.classList.remove('theme-dark')
    document.body.classList.remove('theme-light')
    document.body.classList.add('theme-light')
  }, [])
  // }, [theme])
}

////////////////////////////////////////////////////////////////////////////////
export function useSignon() {
  const [state, dispatch] = useContext(GlobalContext)

  // initialize apollo
  useEffect(() => {
    dispatch({ type: R_GLOBAL.APOLLO_RESET, dispatch })
  }, [dispatch])

  // Load user, refetch when we are able to do so (have a validation token)
  const { reload, refresh } = state.signon
  useEffect(() => {
    if (refresh) {
      getAccessToken(dispatch)
    }
  }, [refresh, dispatch])

  // Fetch profile after done signing in or if `reload` changes
  useEffect(() => {
    let isMounted = true
    if (reload) {
      state.apollo
        .query({
          query: LOAD_USER,
          variables: { id: 'self' },
          fetchPolicy: 'network-only'
        })
        .then(handleOneResponse('data', 'users'))
        .then(([self]) => {
          if (!isMounted) return null

          dispatch({ type: R_GLOBAL.USER_SET, value: self }) // first things first: set user

          const activeOrgId = userToActiveOrgId(self)
          const fallbackOrgId = fallbackOrg(self)?.id

          if (!isUuid(activeOrgId) && isUuid(fallbackOrgId)) {
            const settings = updateSettings(self.settings, {
              activeOrgId: fallbackOrgId
            })
            return state.apollo
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  id: self.id,
                  user: {
                    settings: JSON.stringify(settings)
                  }
                }
              })
              .then(handleOneResponse('data', 'updateUser'))
              .then((self) => {
                dispatch({ type: R_GLOBAL.USER_SET, value: self })
              })
          }
        })
        .catch(notifier.onCatch(dispatch))
    }
    return () => {
      isMounted = false
    }
  }, [reload, state.apollo, dispatch])
}
