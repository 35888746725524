import React, { useContext } from 'react'

import { CheckIcon } from 'tools/Uniform'
import { R_SIGNON } from 'utils/signon'

import { GlobalContext, R_GLOBAL } from 'reducer/global'
import { windowPreferredTheme } from 'reducer/global/hooks'

function ThemeToggle({ user, className = '', children = undefined }) {
  const [, dispatch] = useContext(GlobalContext)
  const settings = { ...user.settings }
  const deviceTheme = windowPreferredTheme()
  const ignored = settings.theme !== undefined
  const save = (settings) =>
    user.onSave(
      { token: 'settings', component: 'user', componentId: false },
      JSON.stringify(settings),
      () => dispatch({ type: R_SIGNON.RELOAD_USER })
    )

  return (
    <>
      <div className={className}>
        <label className="f5 b db mb3 heading">View Mode</label>
      </div>
      <div className="mb3">
        For your reading preference, we offer a dark and a light mode. By default
        we use the theme set by your device ({deviceTheme}). You can override this
        here.
      </div>

      <Choose
        value={!ignored}
        onClick={() => {
          delete settings['theme']
          save(settings)
          dispatch({ type: R_GLOBAL.PAGE_SET, value: { theme: deviceTheme } })
        }}
      >
        Use my device setting ({deviceTheme})
      </Choose>
      <Choose
        value={settings.theme === 'theme-dark'}
        onClick={() => {
          settings.theme = 'theme-dark'
          save(settings)
          dispatch({ type: R_GLOBAL.PAGE_SET, value: { theme: 'theme-dark' } })
        }}
      >
        Ignore my device setting and use the <b className="ph1">dark theme</b>
      </Choose>
      <Choose
        value={settings.theme === 'theme-light'}
        onClick={() => {
          settings.theme = 'theme-light'
          save(settings)
          dispatch({ type: R_GLOBAL.PAGE_SET, value: { theme: 'theme-light' } })
        }}
      >
        Ignore my device setting and use the <b className="ph1">light theme</b>
      </Choose>
    </>
  )
}

function Choose({ onClick, children, value }) {
  return (
    <div
      className="mv2 flex-items pl2 w-100 bg-hilite-hover pointer br3 f3"
      onClick={onClick}
    >
      <CheckIcon checked={value} className="primary mr2" />
      {children}
    </div>
  )
}

export default ThemeToggle
