import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { R_SIGNON } from 'utils/signon'

import paths from 'common/paths'
import GlobalContext from 'reducer/global'
import { usePage } from 'reducer/global/hooks'

import SignonInline from './SignonInline'

export function Signout() {
  const [, dispatch] = useContext(GlobalContext)
  const history = useHistory()

  useEffect(() => {
    dispatch({ type: R_SIGNON.SIGN_OUT })
    history.replace('/signon')
  }, [dispatch, history])

  return null
}

function Signon({ heading }) {
  usePage({ section: 'Signon' }, [])

  const history = useHistory()
  const { pathname } = useLocation()
  const [{ user }] = useContext(GlobalContext)

  useEffect(() => {
    if (user.isAuthN) {
      if (user.age < 1) {
        history.push(paths.public.user)
      } else if (pathname.startsWith('/signon')) {
        history.push('/')
      }
    }
  }, [history, user.isAuthN, user.age, pathname])

  return <SignonInline heading={heading} />
}

export default Signon
