import React, { createContext, useReducer } from 'react'

import { Link } from 'tools/Link'
import { freeze } from 'utils/dom'

import { badCode, badPassword } from 'common/Signon/password'
import paths from 'common/paths'

export const DEFAULT_CODE = freeze({
  value: '',
  show: false,
  message: undefined
})

export const DEFAULT_EMAIL = freeze({
  value: '',
  message: undefined
})

const DEFAULT_PASSWORD = freeze({
  value: '',
  value2: '',
  show: false,
  message: undefined
})

export const DEFAULT_STATE = {
  id: '',
  code: DEFAULT_CODE,
  email: DEFAULT_EMAIL,
  password: DEFAULT_PASSWORD,
  requestReset: undefined,
  factors: undefined,
  allowed: undefined,
  signout: false
}

export function changePassword({ password, code, userId }, dispatch, ev, key) {
  let value = ev.target.value.trim()
  let change = undefined
  if (key === 'value') {
    change = { word: value, compare: password.value2, code: code.code }
  } else {
    change = { word: value, compare: password.value, code: code.code }
  }
  const passError = badPassword(change.word, change.compare)
  const codeError = badCode(change, userId)
  const error = passError || codeError
  dispatch({
    key: 'password',
    value: {
      good: !passError && !codeError,
      message: error && <span className="red b">{error}</span>,
      [key]: value
    }
  })
}

export function savePassword({ code, password }, dispatch, mutation, isAuthN) {
  const variables = {
    new: password.value
  }
  if (isAuthN) {
    variables.current = code.value
  } else {
    variables.code = code.value
  }
  mutation({
    variables,
    onCompleted({ changePassword }) {
      if (changePassword.success) {
        if (isAuthN) {
          dispatch({
            signout: true,
            password: { ...DEFAULT_PASSWORD, message: <>Password updated!</> }
          })
        } else {
          message(dispatch, 'password', 'successOut')
        }
      } else {
        message(dispatch, 'password', 'changeFail', isAuthN)
      }
    }
  })
}

////////////////////////////////////////////////////////////////////////////////
// for readability, consolidate all the ugly message-fu into one place
const MESSAGES = {
  needEmail: <>Please provide a valid email address</>,
  submitted: (
    <>
      Your request is submitted. You should receive a message in a few minutes
      with further instructions. If it does not appear soon, also check your Junk
      Mail folder.
    </>
  ),
  successOut: (
    <>
      <div className="green mb3 b">Password updated!</div>
      You can now{' '}
      <Link to={paths.public.signon}>
        <b>Sign In</b>
      </Link>
    </>
  )
}

export function message(dispatch, key, name, reason) {
  switch (name) {
    case 'reason':
      return dispatch({
        key,
        value: {
          message: <span className="red">Unable to request reset: {reason}</span>
        }
      })
    case 'changeFail':
      return dispatch({
        key,
        value: {
          message: (
            <div>
              <span className="red b mr2">Unable to change password.</span>{' '}
              {reason ? (
                <>Are you sure the original password is correct?</>
              ) : (
                <>
                  Are you sure the code is correct and has <b>not expired?</b>
                </>
              )}{' '}
              <div className="mt1">
                Do you want to{' '}
                {reason ? (
                  'Request a new Reset Code?'
                ) : (
                  <a className="b" href={paths.public.password}>
                    Request a new Reset Code?
                  </a>
                )}
              </div>
            </div>
          )
        }
      })
    default:
      dispatch({ key, value: { message: MESSAGES[name] } })
  }
}

////////////////////////////////////////////////////////////////////////////////
export function reducer(state, { key, value, ...update }) {
  if (key) {
    return freeze({
      ...state,
      [key]: freeze({ ...state[key], ...value })
    })
  } else {
    return freeze({ ...state, ...update })
  }
}

function defaultState({ code, email, userId, ...args }) {
  return reducer(DEFAULT_STATE, {
    id: userId,
    code: freeze({ ...DEFAULT_CODE, value: code || '' }),
    email: freeze({ ...DEFAULT_EMAIL, value: email || '' }),
    ...args
  })
}

export const PasswordContext = createContext(null)
export const PasswordProvider = ({ children, ...initArgs }) => {
  const [state, dispatch] = useReducer(reducer, initArgs, defaultState)

  return (
    <PasswordContext.Provider value={[state, dispatch]}>
      {children}
    </PasswordContext.Provider>
  )
}
export default PasswordContext
