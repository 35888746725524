import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { stopBubbling } from 'utils/event'

import Activity from './Activity'
import style from './index.module.scss'
import { NavTabsContext, NavTabsProvider, R_TABS, makeTab } from './reducer'

////////////////////////////////////////////////////////////////////////////////
const defaultProps = {}
function useTabs({ tabs, param, props = defaultProps }) {
  const [, dispatch] = useContext(NavTabsContext)
  const params = useParams()

  useEffect(() => {
    dispatch({ type: R_TABS.SET, value: { tabs, props, param, params } })
  }, [tabs, dispatch, props, param, params])
}

////////////////////////////////////////////////////////////////////////////////
function activeTabs(origin, active, inactive) {
  let activity = active.reduce(
    (acc, tab) => {
      acc[tab] = true
      return acc
    },
    { ...(origin.tabProps.activity ?? {}) }
  )
  activity = inactive.reduce((acc, tab) => {
    delete acc[tab]
    return acc
  }, activity)
  origin.tabProps = { ...origin.tabProps, activity }
  return origin
}

function addTab(orig, tab) {
  return closeTab(orig, tab.id).concat(makeTab(tab))
}

function removeTab(orig, view) {
  return orig.filter((t) => t.id !== view)
}

function openTab(orig, tab, meta = {}) {
  return orig.map((t) => {
    if (t.id === tab.id) {
      return { ...t, view: () => ({}), ...tab, ...meta }
    } else {
      return t
    }
  })
}

function closeTab(orig, view) {
  return orig.map((t) => {
    if (t.id === view) {
      return { ...t, view: () => false }
    } else {
      return t
    }
  })
}

////////////////////////////////////////////////////////////////////////////////
function NavTabs({
  path,
  className = 'mt4',
  controls = undefined,
  raw = false,
  children = undefined
}) {
  const [{ tabs, order, param, props }] = useContext(NavTabsContext)
  const history = useHistory()
  const params = useParams()
  let view = params[param]
  let current = tabs[view]
  if (!current) {
    view = order.find((id) => tabs[id].orig)
    current = tabs[view]
    if (!current) {
      return null
    }
  }

  const { Component: Tab, hideTabs, className: tabClass } = current
  if (hideTabs) {
    return <Tab tab={view} view={view} {...props} />
  }
  return (
    <>
      <div className={`${tabClass ?? className} ${style.tablist}`}>
        <div className={style.tabs}>
          {order.map((id) => {
            const tab = tabs[id]
            const args = { view: tab.id, tab, params, tabs, props, current }
            if (!tab.view(args)) return null
            return (
              <div
                key={id}
                className={`${style.tab} ${tab.close ? style.tabClose : ''}
                theme-tab  ${view === id ? 'active' : ''} f7 f3-l f3-m relative`}
                onClick={(ev) => {
                  stopBubbling(ev)
                  if (tab.path) {
                    history.push(tab.path(args))
                  } else {
                    history.push(path(args))
                  }
                }}
              >
                {tab.name(args)}
                <Activity show={tab.activity} />
                {tab.close ? (
                  <div className={style.close}>
                    <div
                      className={'button clear pv1 ph2'}
                      onClick={(ev) => {
                        stopBubbling(ev)
                        tab.close(id, history, params)
                      }}
                    >
                      <i className="fas fa-times" />
                    </div>
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>
        {controls ? <div className={style.controls}>{controls}</div> : null}
      </div>
      <div className={raw ? '' : 'base-frame max-view-page'}>
        {children}
        <Tab tab={view} view={view} {...props} />
      </div>
    </>
  )
}

export default NavTabs
export {
  NavTabsProvider,
  NavTabsContext,
  NavTabs,
  useTabs,
  makeTab,
  openTab,
  closeTab,
  addTab,
  removeTab,
  activeTabs,
  R_TABS
}
