import gql from 'graphql-tag'

export const userListValues = `
  id
  handle
  name
  verified
  lastSeen
`

const emailValues = 'id,address,primary,verified'
const userLoadValues = `
  ${userListValues}
  emails {${emailValues}}
  phones {id,number,primary,verified}
  settings
  access { roles, actions }
  authStatus
  orgMemberships { id role org { id shortId name updatedAt status state data(type: "companyaddr") { id type value } }}
  activeOrg { id shortId name notices }
  factors(type: "password") { id type expiresAt }
  updatedAt
  insertedAt
`

export const LOAD_USER = gql`
query users($id: String!) {
  users(id: $id) {
    success
    reason
    results {
      ${userLoadValues}
    }
  }
}
`

export const UPDATE_USER = gql`
mutation updateUser(
    $id: String, $action: UserChangeActions,
    $user: InputUser, $handle: InputHandle, $email: InputEmail,
    $phone: InputPhone, $data: InputUserData, $role: InputRole)
  {
    updateUser(
      id: $id, action: $action,
      user: $user, handle: $handle, email: $email,
      phone: $phone, data: $data, role: $role)
    {
      success
      reason
      result {
        ${userLoadValues}
      }
    }
  }
`

export const MY_FACTORS = gql`
  query userFactors($type: String, $id: String!) {
    users(id: $id) {
      success
      reason
      results {
        id
        factors(type: $type) {
          id
          type
          expiresAt
        }
      }
    }
  }
`
//
// export const NOTIFY_PREFS = gql`
//   query notifyPrefs($id: String!) {
//     users(id: $id) {
//       success
//       reason
//       results {
//         id
//         notifyPrefs {
//           id
//           type
//           pref
//           allow
//           updatedAt
//         }
//       }
//     }
//   }
// `
//
// export const SET_NOTIFY_PREF = gql`
//   mutation upsertNotifyUserPref($pref: InputNotifyUserPref!) {
//     upsertNotifyUserPref(pref: $pref) {
//       success
//       reason
//       result {
//         id
//         type
//         pref
//         allow
//       }
//     }
//   }
// `

//export const GEN_APIKEY = gql`
//mutation genApiKey {
//genApiKey {
//access
//validation
//}
//}
//`

//export const GET_INVITES = gql`
//mutation getInvites {
//getInvites {
//codes
//}
//}
//`

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String, $id: String) {
    requestPasswordReset(email: $email, id: $id) {
      success
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword($current: String, $new: String!, $code: String) {
    changePassword(current: $current, new: $new, code: $code) {
      success
      reason
    }
  }
`

export const GET_ACCESS = gql`
  query getAccess($type: AccessDomain!, $refId: String!) {
    getAccess(type: $type, refId: $refId)
  }
`

//export const DELETE_ACCOUNT = gql`
//mutation deleteAccount($code: String) {
//deleteAccount(code: $code) {
//success
//reason
//}
//}
//`
