import React, { Suspense, lazy, useContext } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'

// todo: resolve (not overlay)
import { LoadingTop as Loading, useWait } from 'tools/Loading'

// always load
import Signon, { Signout } from 'common/Signon'
import paths from 'hub/paths'
import GlobalContext from 'reducer/global'
import { useSignon, useTheme } from 'reducer/global/hooks'

// load on demand
const Admin = lazy(() => import('hub/sys/Admin'))
const Reports = lazy(() => import('hub/sys/Reports'))
const Compute = lazy(() => import('hub/Compute'))
const GetStarted = lazy(() => import('hub/GetStarted'))
const Home = lazy(() => import('hub/Root/Home'))
const Inventory = lazy(() => import('hub/sys/Inventory'))
const Org = lazy(() => import('hub/Org'))
const Registrations = lazy(() => import('hub/sys/Registrations'))
const ResetPassword = lazy(() => import('common/User/ChangePassword/Public'))
const ReturnCode = lazy(() => import('hub/ReturnCode'))
const Root = lazy(() => import('hub/Root'))
const Sku = lazy(() => import('hub/sys/Sku'))
const Support = lazy(() => import('hub/Support'))
const Taxonomy = lazy(() => import('hub/sys/Taxonomy'))
const User = lazy(() => import('common/User'))
const Messages = lazy(() => import('common/User/Notices'))
const Dev = lazy(() => import('common/sys/Dev'))

function SignonRequired({ children, isVerified }) {
  const wait = useWait(2000)

  if (isVerified) {
    return children
  }

  if (wait)
    return (
      <div className="loading-overlay">
        <div className="loading" />
      </div>
    )

  return <Signon />
}

function AppRouter() {
  const [{ apollo, user }] = useContext(GlobalContext)
  const isVerified = user.isIdentified && user.verified

  useSignon()
  useTheme()

  return (
    <ApolloProvider client={apollo}>
      <Router>
        <Root>
          <Switch>
            <Route exact path="/">
              <Suspense fallback={<Loading />}>
                <SignonRequired isVerified={isVerified}>
                  <Home />
                </SignonRequired>
              </Suspense>
            </Route>
            <Route path={paths.public.computeRoute}>
              <SignonRequired isVerified={isVerified}>
                <Suspense fallback={<Loading />}>
                  <Compute />
                </Suspense>
              </SignonRequired>
            </Route>
            <Route path={paths.sys.taxonomyRoute}>
              <SignonRequired isVerified={isVerified}>
                <Suspense fallback={<Loading />}>
                  <Taxonomy />
                </Suspense>
              </SignonRequired>
            </Route>
            <Route path={paths.public.support}>
              <Suspense fallback={<Loading />}>
                <Support />
              </Suspense>
            </Route>
            <Route path={paths.public.orgRoute}>
              <SignonRequired isVerified={isVerified}>
                <Suspense fallback={<Loading />}>
                  <Org />
                </Suspense>
              </SignonRequired>
            </Route>
            <Route path={paths.public.userRoute}>
              <SignonRequired isVerified={isVerified}>
                <Suspense fallback={<Loading />}>
                  <User />
                </Suspense>
              </SignonRequired>
            </Route>
            <Route path={paths.public.messages}>
              <SignonRequired isVerified={isVerified}>
                <Suspense fallback={<Loading />}>
                  <Messages />
                </Suspense>
              </SignonRequired>
            </Route>
            <Route path={paths.public.passwordRoute}>
              <Suspense fallback={<Loading />}>
                <ResetPassword />
              </Suspense>
            </Route>
            <Route path={paths.public.startRoute}>
              <Suspense fallback={<Loading />}>
                <GetStarted />
              </Suspense>
            </Route>
            <Route path={paths.public.signon}>
              <Signon />
            </Route>
            <Route path={paths.public.signout}>
              <Signout />
            </Route>
            <Route path={paths.public.returnCodeRoute}>
              <Suspense fallback={<Loading />}>
                <ReturnCode />
              </Suspense>
            </Route>
            <Route path={paths.sys.inventoryRoute}>
              <SignonRequired isVerified={isVerified}>
                <Suspense fallback={<Loading />}>
                  <Inventory />
                </Suspense>
              </SignonRequired>
            </Route>
            <Route path={paths.sys.registrationsRoute}>
              <SignonRequired isVerified={isVerified}>
                <Suspense fallback={<Loading />}>
                  <Registrations />
                </Suspense>
              </SignonRequired>
            </Route>
            <Route path={paths.sys.skuRoute}>
              <SignonRequired isVerified={isVerified}>
                <Suspense fallback={<Loading />}>
                  <Sku />
                </Suspense>
              </SignonRequired>
            </Route>
            <Route path={paths.sys.adminRoute}>
              <Suspense fallback={<Loading />}>
                <SignonRequired isVerified={isVerified}>
                  <Admin />
                </SignonRequired>
              </Suspense>
            </Route>
            <Route path={paths.sys.reportsRoute}>
              <Suspense fallback={<Loading />}>
                <SignonRequired isVerified={isVerified}>
                  <Reports />
                </SignonRequired>
              </Suspense>
            </Route>
            <Route path={paths.sys.devRoute}>
              <Suspense fallback={<Loading />}>
                <SignonRequired isVerified={isVerified}>
                  <Dev />
                </SignonRequired>
              </Suspense>
            </Route>
          </Switch>
        </Root>
      </Router>
    </ApolloProvider>
  )
}

export default AppRouter
