import React, { useContext, useEffect } from 'react'

import config from 'config'

import NotifyPopup from 'tools/Notify/Popup'

import Footer from 'common/Footer'
import GlobalContext from 'reducer/global'

import NavBar from './NavBar'

function Root({ children }) {
  const [
    {
      page: { title, section }
    }
  ] = useContext(GlobalContext)

  useEffect(() => {
    if (title || section) {
      document.title = (title || section) + ' - ' + config.name
    } else {
      document.title = config.name
    }
  }, [title, section])

  return (
    <>
      <div id="body" className="base-body">
        {children}
        <NotifyPopup />
      </div>
      <Footer />
      <NavBar />
    </>
  )
}

export default Root
