import React, { useContext } from 'react'

import config from 'config'

import Input from 'tools/Input'
import { Help } from 'tools/Uniform'
import { Label } from 'tools/Uniform'
import { TabFrame } from 'tools/Uniform'

import GlobalContext from 'reducer/global'

// temporary until we refactor all of this within SectionRouter
import { AdminStripe } from '../Account'
import ThemeToggle from '../ThemeToggle'
import UserContext from '../reducer'
import { R_USER } from '../reducer'
import EmailEdit from './EmailEdit'
import PhoneEdit from './PhoneEdit'

function Profile() {
  const [{ user: actor }] = useContext(GlobalContext)
  const [user, dispatch] = useContext(UserContext)

  return (
    <TabFrame className="pa0">
      <AdminStripe user={user} editor={actor}>
        <Input
          wrapClass="normal"
          labelClass="mb3"
          label={
            <>
              Handle
              <Help title="Handle (Username)">
                Your Handle is a shorter name used to represent you at{' '}
                {config.name}. It is unique to you. Nobody else will have the same
                handle.
              </Help>
            </>
          }
          component="handle"
          token="handle"
          meta={{ id: user.id }}
          maxLength={30}
          value={user.handle || ''}
          onChange={(value) =>
            dispatch({ type: R_USER.CHANGE, token: 'handle', value })
          }
          onSave={user.onSave}
        />

        <Input
          label="Name"
          token="name"
          wrapClass="normal"
          component="user"
          meta={{ id: user.id }}
          maxLength={70}
          value={user.name || ''}
          onChange={(value) =>
            dispatch({ type: R_USER.CHANGE, token: 'name', value })
          }
          onSave={user.onSave}
        />

        <Label>Email</Label>
        <EmailEdit user={user} />
        <Label>Phone</Label>
        <PhoneEdit user={user} />

        <div className="mt4">
          <ThemeToggle user={user} />
        </div>
      </AdminStripe>
    </TabFrame>
  )
}

// <DeleteUser />

export default Profile
