import { authDebug, startSignon } from '../../utils/signon'

export function doSignonFederated({ state, type, authResponse, dispatch }) {
  authDebug(1, '[Signon].doSignonFederated()')
  return startSignon({
    state,
    vars: {
      type: type,
      data: { auth: authResponse }
    },
    dispatch
  })
}
