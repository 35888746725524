import { sum } from './math.js'
import { graphemes } from './string.js'

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(hexToRGB('#cccccc'), [204, 204, 204])
 * t.deepEqual(hexToRGB('cccccc'), [204, 204, 204])
 * t.deepEqual(hexToRGB('CCCCCC'), [204, 204, 204])
 * t.deepEqual(hexToRGB('#CcCcCc'), [204, 204, 204])
 * t.deepEqual(hexToRGB('#ccc'), [204, 204, 204])
 * t.deepEqual(hexToRGB('ccc'), [204, 204, 204])
 * ```
 */
export function hexToRGB(hex) {
  const is3digit = hex.replace('#', '').length === 3
  return hex
    .replace('#', '')
    .split('')
    .map((c) => (is3digit ? c + c : c))
    .join('')
    .match(/.{1,2}/g)
    .map((rgb) => parseInt(rgb, 16))
}

/*
 * @doctests
 *
 * ```js
 * t.is(rgbToHex([204, 204, 204]), '#cccccc')
 * ```
 */
export function rgbToHex([r, g, b]) {
  return '#' + [r, g, b].map((c) => c.toString(16).padStart(2, '0')).join('')
}

/*
 * @doctests
 *
 * ```js
 * t.is(hexToRGBA("#ccc", 0.5), 'rgba(204,204,204,0.5)')
 * ```
 */
export function hexToRGBA(hex, alpha = 1) {
  return `rgba(${hexToRGB(hex).concat(alpha).join(',')})`
}

/*
 * @doctests
 *
 * ```js
 * t.is(invert('#ffffff'), '#000000')
 * t.is(invert('#000000'), '#ffffff')
 * t.is(invert('#cccccc'), '#333333')
 * ```
 */
export function invert(hex) {
  return rgbToHex(hexToRGB(hex).map((c) => 255 - c))
}

/*
 * @doctests
 *
 * ```js
 * t.is(rainbowId('9BA7E2EA-8372-4550-8BF5-8883BC0DB64CZ'), 12)
 * t.is(rainbowId('83446BC5-57F7-4FB1-846F-720AB4669DF7'), 7)
 * t.is(rainbowId('B9399C96-91E9-413E-9EC3-0B1768B4896D'), 13)
 * ```
 */
export function rainbowId(uuid) {
  return parseInt(uuid.slice(28, 36), 16) % 16
}

/*
 * @doctests
 *
 * ```js
 * t.is(prism('nglg01'), 2)
 * t.is(prism('usca00'), 8)
 * t.is(prism('usca1a'), 3)
 * t.is(prism('usca1b'), 9)
 * t.is(prism('usca2a'), 8)
 * t.is(prism('usks1a'), 3)
 * t.is(prism('usny01'), 15)
 * t.is(prism('ustn1a'), 10)
 * t.is(prism('usva1a'), 12)
 * t.is(prism('usva1b'), 2)
 * ```
 */
export function prism(text, range = 16) {
  return sum(graphemes(text).map((c, i) => (i + 1) * c.codePointAt(0))) % range
}
