import { R_NOTIFY, reducer as notifyReducer } from 'tools/Notify/reducer'
import { defaultState as defaultNotify } from 'tools/Notify/reducer'
import apollo from 'utils/apollo'
import { freeze } from 'utils/dom'
import { authRequest, signonDropStates, signonInitialState } from 'utils/signon'
import { defaultUser, normalizeUser } from 'utils/user'

import { signonReducer } from 'common/Signon/reducer'

////////////////////////////////////////////////////////////////////////////////
export function generateBaseGlobalState(seed) {
  return freeze({
    notify: defaultNotify,
    // put seed after notify so it can override notify
    ...seed,
    apolloInit: false,
    apollo: apollo(() => {}),
    page: {
      hideChrome: false,
      title: '',
      theme: 'theme-dark',
      section: 'Controller',
      innerNav: undefined,
      navOverride: undefined
    },
    user: defaultUser(),
    signon: signonInitialState()
  })
}

export function resetBaseGlobalState(oldState, newSeed) {
  oldState.apollo.cache.reset()
  signonDropStates()
  authRequest('signout', {})

  // keep notify messages across state transitions
  return generateBaseGlobalState({ notify: oldState.notify, ...newSeed })
}

export const BASE_R_GLOBAL = {
  // R_SIGNON has 0-1000
  APOLLO_RESET: 1001,
  NOTIFY: 2000,
  USER_SET: 4001,
  USER_MERGE: 4002,
  PAGE_SET: 6001
}

export function baseGlobalReducer(state, { type, ...action }) {
  switch (type) {
    case BASE_R_GLOBAL.APOLLO_RESET:
      return { ...state, apolloInit: true, apollo: apollo(action.dispatch) }

    case BASE_R_GLOBAL.NOTIFY:
      return { ...state, notify: notifyReducer(state.notify, action.value) }

    case BASE_R_GLOBAL.USER_SET: {
      const user = normalizeUser(action.value, true)
      // TODO: How to best bring this into global context?
      // state.org = user.orgsD[state.org.id] ?? {}
      return { ...state, user }
    }

    case BASE_R_GLOBAL.USER_MERGE:
      return { ...state, user: { ...state.user, ...action.value } }

    case BASE_R_GLOBAL.PAGE_SET: {
      return {
        ...state,
        page: {
          ...state.page,
          section: undefined,
          title: undefined,
          innerNav: undefined,
          theme: undefined,
          NavOverride: undefined,
          ...action.value
        },
        // safetey net, when we change page state also kick notify
        notify: notifyReducer(state.notify, { type: R_NOTIFY.EXPIRE })
      }
    }

    default:
      return signonReducer(state, type, action)
  }
}
