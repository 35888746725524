import React from 'react'

// TODO: update to allow more variants, such as off-host and on-host
export const isLocalDev = process.env.NODE_ENV === 'development'

// IO.inspect
console.inspect = (val, ...labels) => {
  console.info(...labels, val)
  return val
}

const config = {
  // note: Lane was used in logging events, but if we're not going to use it,
  // we should remove it. -BJG
  // lane: 'd', // @LANE@
  build: 'DEV', // @BUILD@
  app: 'narf',
  ws: '/socket',
  graphql: '/graphql', // @GRAPHQL@
  api: '/v1/api/',
  authapi: '/authx/v1/api/', // @AUTHAPI@
  pub: '', // used by file uploads
  logo: <i className="fas fa-plug" />,
  logotext: (
    <span className="mr2">
      <i className="fas fa-plug mr2" /> Cato Metal Console
    </span>
  ),
  name: 'Cato',
  gaKey: 'G-Q802RFGQ71', // @GAKEY@
  auth: {
    local: true,
    federated: ['google'],
    google: {
      appId:
        '334472625324-ecb7lih2719bbpe1tv3itiq003e7pa4h.apps.googleusercontent.com'
    }
  },
  // the above settings are overridden by site-specific runtime configs which
  // come in from `/assets/config/config.js` which is loaded via the <head>
  // defined in the public/index.html file; which is loaded and put into
  // window.config
  ...window.config
}

window.VERSION_OUTDATED = false
async function check_version() {
  const resp = await fetch('/assets/config/config.json')
  const data = await resp.json()
  if (config.build !== data.build) {
    window.VERSION_OUTDATED = true
  }
}

const RECHECK_INTERVAL = 3600_000 // every hour
setInterval(() => check_version(), RECHECK_INTERVAL)

export default config
