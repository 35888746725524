import React, { useState } from 'react'

import { Numeric } from 'tools/Input/Old'

function PhoneEdit({ user }) {
  // const deletable = user.phones.length > 1
  const deletable = true
  return (
    <div className="w-100 ba br2 mb2 input pa2">
      {user.phones.map((phone) => (
        <ShowPhone
          key={phone.id}
          phone={phone}
          deletable={deletable}
          user={user}
        />
      ))}
      <AddPhone user={user} />
    </div>
  )
}

function ShowPhone({ phone, deletable, user }) {
  return (
    <div
      key={phone.id}
      className="bg-hilite-hover flex justify-between items-center mb2"
    >
      {phone.number}
      {deletable ? (
        <i
          className="fas fa-trash hover pa1 br2 pointer f7 bg-hilite-hover"
          onClick={() =>
            user.onSave(
              {
                component: 'phone',
                componentId: false,
                meta: { action: 'REMOVE' },
                token: 'id'
              },
              phone.id
            )
          }
        />
      ) : null}
    </div>
  )
}

function AddPhone({ user }) {
  const [adding, setAdding] = useState(false)
  const [phone, setPhone] = useState('')
  const [msg, setMsg] = useState('')

  if (adding) {
    return (
      <>
        <div className="flex-items">
          <Numeric
            value={phone}
            onChange={setPhone}
            onSave={() => {}}
            token="phone"
            format="(###) ###-####"
            mask="_"
            icons={false}
          />
          <button
            className="pa2 ml2 ph4"
            onClick={() => {
              user.onSave(
                { component: 'phone', componentId: false, token: 'phone' },
                phone,
                (result) => {
                  setAdding(false)
                  setPhone('')
                  setMsg('')
                },
                (bad) => {
                  setMsg(bad)
                }
              )
            }}
          >
            Add
          </button>
        </div>
        {msg ? <div className="mv2 red">{msg}</div> : null}
      </>
    )
  }
  return (
    <button className="f3 pa2 mb0 mt1 ph4 tl" onClick={() => setAdding(true)}>
      <i className="fas fa-plus mr2" />
      Add Phone
    </button>
  )
}

export default PhoneEdit
