export function stopEvent(e) {
  e.stopPropagation()
  e.preventDefault()
}

export function stopBubbling(e) {
  e.stopPropagation()
}

export function preventDefault(e) {
  e.preventDefault()
}

export function isRightClick(e) {
  return e?.nativeEvent?.which === 3
}
