import React, { createContext, useReducer } from 'react'

////////////////////////////////////////////////////////////////////////////////
//
// view(tabProps, globalProps)
//  returns:
//    -- null => do not show tab
//    -- props => show tab, with these props
//

export const R_TABS = {
  SET: 1
}

export function makeTab({
  id,
  name,
  key = undefined,
  // orig is default if there is no tab selected (default being a reserved word)
  orig = false,
  Component,
  props = {},
  // hideTabs says when this tab is selected no tab framing exists
  hideTabs = false,
  more = '',
  path = undefined,
  close = undefined,
  className = undefined,
  view = (args, params) => ({ ...props, ...params })
}) {
  return {
    id,
    key: key || id,
    name,
    Component,
    props,
    view,
    className,
    orig,
    close,
    hideTabs,
    more,
    path
  }
}

const defaultState = {
  order: [],
  tabs: {},
  param: '',
  props: {}
}

function normalizeTabs(state, { tabs, props, param, params }) {
  const activity = props.activity || {}
  if (!tabs) {
    console.log('NO TABS? BAD STATE?')
  }
  return (tabs || []).reduce(
    (state, tab) => {
      let name = tab.name
      if (typeof name === 'string') {
        name = (args) => tab.name
      }
      state.tabs[tab.id] = {
        ...tab,
        name,
        activity: activity[tab.id]
      }
      state.order.push(tab.id)
      return state
    },
    { ...defaultState, order: [], tabs: {}, param, props }
  )
}

export function reducer(state, { type, value }) {
  switch (type) {
    case R_TABS.SET:
      return normalizeTabs(state, value)

    default:
      throw new Error(`no such action.type: ${type}!`)
  }
}

export const NavTabsContext = createContext(null)
export const NavTabsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  return (
    <NavTabsContext.Provider value={[state, dispatch]}>
      {children}
    </NavTabsContext.Provider>
  )
}

export default NavTabsContext
