import React from 'react'

// import { SECTION as orgSection } from 'hub/Org'
import { SECTIONS } from 'tools/Uniform/Sections'
// import { Link } from 'tools/Link'
import { cleanPath } from 'utils/string'
import { ellipsisTruncate } from 'utils/string'
import { ROLES } from 'utils/user'

import paths, { orgPath } from 'hub/paths'

import { corpSiteLink } from './SitemapNav'

function CorpLink({ to, children, className = '' }) {
  // this is only for our corp site, we want the normal experience
  return (
    <a href={to} className={className}>
      {children}
    </a>
  )
}

export const admin = [
  {
    id: 'admin-div',
    divider: true,
    roles: [ROLES.HUB_MANAGER, ROLES.HUB_OPS]
  },
  {
    id: 'admin-header',
    miniLabel: 'Admin',
    roles: [ROLES.HUB_MANAGER, ROLES.HUB_OPS]
  },
  {
    to: paths.sys.registrations,
    ...SECTIONS.registration
  },
  {
    to: paths.sys.inventory,
    ...SECTIONS.server
  },
  {
    to: paths.sys.sku,
    ...SECTIONS.sku
  },
  {
    to: paths.sys.taxonomy,
    ...SECTIONS.taxonomy
  },
  {
    to: paths.sys.reports,
    ...SECTIONS.report
  },
  {
    to: paths.sys.adminUsers,
    ...SECTIONS.admin_user
  },
  {
    to: paths.sys.adminOrgs,
    ...SECTIONS.admin_org
  }
]

const manage = (org = {}) =>
  [
    {
      ...SECTIONS.org,
      displayOrgName: true,
      to: paths.public.org
    },
    {
      label: <span className="b">{ellipsisTruncate(org?.name ?? '', 16)}</span>,
      icon: 'fas fa-star',
      displayOrgName: true,
      hide: org.name == null,
      showHome: false,
      to: orgPath(org.shortId, 'settings')
    },
    {
      ...SECTIONS.compute,
      displayOrgName: true,
      hide: org.name == null,
      to: cleanPath(paths.public.compute, org.shortId ?? '_', 'server')
    },
    ...admin
  ].filter((item) => !item.hide)

export const manageSections = (org, section) =>
  manage(org).find(({ label }) => label === section)

const hamburger = [
  {
    to: paths.public.user,
    label: 'Preferences'
  },
  {
    label: 'Knowledgebase',
    icon: 'fas fa-book',
    desc: 'Learn about using the Cato Console and API',
    to: 'https://cato.digital/kb/',
    Linker: CorpLink
  },
  {
    label: 'Cato Hub',
    showHome: false,
    to: '/'
  },
  {
    label: 'Cato Website',
    showHome: false,
    to: corpSiteLink('/'),
    Linker: CorpLink
  },
  {
    to: 'https://cato.digital/support/',
    label: 'Support',
    icon: 'fas fa-file',
    Linker: CorpLink
  },
  {
    divider: true,
    id: 'signout-div'
  },
  {
    to: paths.public.signout,
    label: 'Sign Out'
  }
]

export default { manage, admin, manageSections, hamburger }
